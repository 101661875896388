import { z } from '../utils/npm'

export const getInTouchSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  email: z.string().email('Invalid email format'),
  message: z.string().min(1, 'Message is required')
})

export const getInTouchFormFields = [
  {
    name: 'name',
    type: 'text',
    placeholder: 'Enter your name',
    component: 'Input',
    classList: 'border border-slate-500 input_field custom_name_class'
  },
  {
    name: 'email',
    type: 'email',
    placeholder: 'Enter your email',
    component: 'Input',
    classList:
      'border border-slate-500 input_field input_email custom_email_class'
  },
  {
    name: 'message',
    placeholder: 'Type your message here',
    component: 'TextArea',
    rows: 10,
    classList:
      'border border-slate-500 input_field w-full input_field_textarea overflow-x-hidden custom_message_class'
  }
]

export const newsLetterSchema = z.object({
  email: z.string().email('Invalid email format')
})

export const newsLetterFormFields = [
  {
    name: 'email',
    type: 'email',
    placeholder: 'Enter your email',
    component: 'Input',
    classList: 'email-input'
  }
]

export const loginSchema = z.object({
  email: z.string().min(1, 'Email is required').email('Invalid email format'),
  password: z.string().min(6, 'Password must be at least 6 characters'),
  remember: z.boolean().optional()
})

export const deleteAccountSchema = z.object({
  firstName: z.string().min(2, 'First Name is required'),
  number: z.string().min(12, 'Contact Number is required'),
  email: z.string().optional(),
  message: z.string().min(1, 'Message is required')
})

export const loginFormFields = [
  {
    name: 'email',
    type: 'email',
    label: 'Email',
    classList: 'w-full mb-8 inputField',
    errorClass: 'text-red-500 text-sm error-text_form absolute -bottom-6'
  },
  {
    name: 'password',
    type: 'password',
    label: 'Password',
    classList: 'w-full mb-4 inputField',
    errorClass: 'text-red-500 text-sm error-text_form absolute -bottom-6'
  },
  {
    name: 'remember',
    type: 'checkbox',
    label: 'Remember me',
    classList: 'w-full mb-4 inputField'
  }
]
export const deleteAccountFormFields = [
  {
    name: 'firstName',
    type: 'text',
    label: 'First Name',
    classList: 'w-full mb-8 inputField',
    errorClass: 'text-red-500 text-sm error-text_form absolute -bottom-6'
  },
  {
    name: 'number',
    type: 'text',
    label: 'Mobile ',
    classList: 'w-full mb-8 inputField',
    errorClass: 'text-red-500 text-sm error-text_form absolute -bottom-6'
  },
  {
    name: 'email',
    type: 'email',
    label: 'Email',
    classList: 'w-full mb-8 inputField',
    errorClass: 'text-red-500 text-sm error-text_form absolute -bottom-6'
  },
  {
    name: 'reason',
    type: 'password',
    label: 'Reason',
    classList: 'w-full mb-4 inputField',
    errorClass: 'text-red-500 text-sm error-text_form absolute -bottom-6'
  },

]

export const registrationSchema = z
  .object({
    firstName: z.string().min(2, 'First Name is required'),
    lastName: z.string().min(2, 'Last Name is required'),
    number: z.string().min(12, 'Contact Number is required'),
    email: z.string().min(1, 'Email is required').email('Invalid email format'),
    // password: z.string().min(6, 'Password must be at least 6 characters'),
    password: z
    .string()
    .min(8, 'Password must be at least 8 characters')
    .max(16, 'Password must be at most 16 characters')
    .regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
    ),
    username: z.string().min(1, 'User Name is required'),
    cPassword: z.string(),
    termsPrivacyPolicies: z.boolean().refine(val => val === true, {
      message: 'You must agree to the Terms and Privacy Policies'
    })
  })
  .refine(data => data.password === data.cPassword, {
    message: 'Passwords must match',
    path: ['cPassword']
  })

export const registrationFormFields = [
  {
    name: 'firstName',
    type: 'text',
    label: 'First Name',
    classList: 'w-full md:w-[49%] mb-5 inputField me-0 md:me-0',
    errorClass: 'text-red-500 text-sm error-text_form absolute -bottom-6'
  },
  {
    name: 'lastName',
    type: 'text',
    label: 'Last Name',
    classList: 'w-full md:w-[49%] mb-5 inputField',
    errorClass: 'text-red-500 text-sm error-text_form absolute -bottom-6'
  },
  {
    name: 'email',
    type: 'email',
    label: 'Email',
    classList: 'w-full mb-8 inputField',
    errorClass: 'text-red-500 text-sm error-text_form absolute -bottom-6'
  },
  {
    name: 'password',
    type: 'password',
    label: 'Password',
    classList: 'w-full mb-4 inputField',
    errorClass: 'text-red-500 text-sm error-text_form absolute -bottom-6'
  },
  {
    name: 'cPassword',
    type: 'password',
    label: 'Confirm Password',
    classList: 'w-full mb-4 inputField',
    errorClass: 'text-red-500 text-sm error-text_form absolute -bottom-6'
  },
  {
    name: 'termsPrivacyPolicies',
    type: 'checkbox',
    label: 'I agree to all the Terms and Privacy Policies',
    classList: 'w-full mb-4 inputField'
  }
]

export const emailSchema = z.object({
  email: z
    .string()
    .min(1, 'Email is required') // Ensure the email is not empty
    .email('Invalid email format'), // Validate the email format
});

export const veriFyCodeSchema = z.object({
  password: z.string().min(6, 'Password must be at least 6 characters'),
});

export const setPasswordSchema = z
  .object({
    // createPassword: z.string().min(6, 'Password must be at least 6 characters'),
    createPassword: z
    .string()
    .min(8, 'Password must be at least 8 characters')
    .max(16, 'Password must be at most 16 characters')
    .regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
    ),
    confirmPassword: z.string(),
  })
  .refine(data => data.createPassword === data.confirmPassword, {
    message: 'Passwords must match',
    path: ['confirmPassword']
  })

export const profileSchema = z.object({
  // img: z
  //   .instanceof(File)
  //   .optional()
  //   .refine((file) => {
  //     if (!file) return true; // If no file is provided, it's valid
  //     const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
  //     return validTypes.includes(file.type);
  //   }, {
  //     message: 'Please upload a valid image (JPEG, PNG, GIF).',
  //   }),
  img: z
    .any()
    .optional()
    .refine((file) => {
      if (!file) return true; // If no file, it's valid (optional field)
      if (file instanceof File) {
        const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
        return validTypes.includes(file.type);
      }
      return false;
    }, {
      message: 'Please upload a valid image (JPEG, PNG, GIF).',
    }),
  name: z.string().min(1, { message: 'Name is required' }),
  email: z.string().email('Invalid email format').min(1, { message: 'Email is required' }),
  firstName: z.string().min(1, { message: 'First name is required' }),
  lastName: z.string().min(1, { message: 'Last name is required' }),
  phone: z.string().min(10, { message: 'Phone number is required' }),
});