import {axios} from "../utils/npm"
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: process.env.REACT_APP_API_URL_2,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    console.error('Request Error:', error); 
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const { response } = error;
    if (response) {
      const { status } = response;
      switch (status) {
        case 401:
          console.warn('Unauthorized access.');
          break;
        case 403:
          console.warn('Forbidden access.');
          break;
        case 500:
          console.error('Server error.');
          break;
        default:
          console.error(`Unhandled error status: ${status}`);
      }
    } else {
      console.error('No response received.');
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
