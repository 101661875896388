import { axiosInstance, axios } from '../utils/npm'
import { decrypt } from '../utils/utils'

export const loginApi = async data => {
  try {
    const response = await axiosInstance.post(
      // `/authentication/login/?platform=Web`,
      `/authentication/login/?platform=Android`,
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        maxBodyLength: Infinity
      }
    )
    return response
  } catch (error) {
    console.error('Failed to login:', error)
    return error
  }
}

export const setSignUpApi = async data => {
  try {
    const response = await axiosInstance.post(
      // `/users/register/?platform=Web`,
      `/users/register/?platform=Android`,
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        maxBodyLength: Infinity
      }
    )
    return response
  } catch (error) {
    console.error('Failed to sign up:', error)
    return error
  }
}

export const getProfileData = async () => {
  try {
    const token = localStorage.getItem('login')
      ? JSON.parse(localStorage.getItem('login'))
      : ''
    const response = await axiosInstance.get(
      '/users/retrieve_update_profile/',
      {
        headers: {
          Authorization: `Bearer ${token?.access}`
        }
      }
    )
    return response
  } catch (error) {
    console.error('Failed to fetch profile data:', error)
    return error
  }
}

export const setProfileDataApi = async datas => {
  try {
    let data = new FormData()
    data.append('username', datas?.username)
    data.append('first_name', datas?.firstName)
    data.append('last_name', datas?.lastName)
    data.append('email', datas?.email)
    data.append('is_superuser', datas?.is_superuser)
    data.append('mobile_number', datas?.mobile_number)
    if (datas.profile_image) {
      data.append('profile_image', datas.profile_image)
    }
    const token = localStorage.getItem('login')
      ? JSON.parse(localStorage.getItem('login'))
      : null
    if (!token?.access) {
      throw new Error('User is not authenticated.')
    }
    const response = await axiosInstance.put(
      '/users/retrieve_update_profile/',
      data,
      {
        headers: {
          Authorization: `Bearer ${token.access}`,
          'Content-Type': 'multipart/form-data'
        }
      }
    )

    return response
  } catch (error) {
    console.error('Failed to update profile data:', error)
    throw error
  }
}

export const forgotPasswordApi = async data => {
  try {
    const response = await axiosInstance.post(
      '/authentication/forgot-password-email-validate/',
      data
    )
    return response
  } catch (error) {
    console.error('Failed to fetch profile data:', error)
    return error
  }
}

export const setNewPasswordApi = async data => {
  try {
    const response = await axiosInstance.post(
      '/authentication/forgot-password/',
      data
    )
    return response
  } catch (error) {
    console.error('Failed to fetch profile data:', error)
    return error
  }
}

export const generateTokenApi = async token => {
  try {
    let data = {
      refresh: token
    }
    data = JSON.stringify(data)
    const response = await axiosInstance.post(
      '/authentication/generate-accesstoken/',
      data
    )
    return response
  } catch (error) {
    return error
  }
}

export const categoryListApi = async (id) => {
  try {
    const token = localStorage.getItem('login')
      ? JSON.parse(localStorage.getItem('login'))
      : ''
    const response = await axiosInstance.get(`/ipo/category-list/${id}/`, {
      headers: {
        Authorization: `Bearer ${token?.access}`
      }
    })
    return response
  } catch (error) {
    console.error('Failed to fetch profile data:', error)
    return error
  }
}

export const accountListApi = async (ipoId, accountId) => {
  try {
    const token = localStorage.getItem('login')
      ? JSON.parse(localStorage.getItem('login'))
      : ''
    // const response = await axiosInstance.get(`/demat-account/`, {
    //   headers: {
    //     Authorization: `Bearer ${token?.access}`
    //   }
    // })
    const response = await axiosInstance.get(`/demat-account/demat-account-for-apply/${ipoId}/${accountId}/`, {
      headers: {
        Authorization: `Bearer ${token?.access}`
      }
    })
    
    return response
  } catch (error) {
    console.error('Failed to fetch profile data:', error)
    return error
  }
}

export const addAccountApi = async (data) => {
  try {
    const token = localStorage.getItem('login')
      ? JSON.parse(localStorage.getItem('login'))
      : ''
    const response = await axiosInstance.post(`/demat-account/`,data, {
      headers: {
        Authorization: `Bearer ${token?.access}`
      }
    })
    return response
  } catch (error) {
    console.error('Failed to fetch profile data:', error)
    return error
  }
}

export const applyIpoApi = async (data) => {
  try {
    const token = localStorage.getItem('login')
      ? JSON.parse(localStorage.getItem('login'))
      : ''
    const response = await axiosInstance.post(`/ipo-bid/`,data, {
      headers: {
        Authorization: `Bearer ${token?.access}`
      }
    })
    return response
  } catch (error) {
    console.error('Failed to fetch profile data:', error)
    return error
  }
}

export const appliedIpoListApi = async(pageNo, pageSize) =>{
  try {
    const token = localStorage.getItem('login')
    ? JSON.parse(localStorage.getItem('login'))
    : ''
    const response = await axiosInstance.get(`/ipo-bid/?page=${pageNo}&page_size=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token?.access}`
      }
    })
    return response
  } catch (error) {
      return error
  }
}

export const getModifyIpoDataApi = async(id) => {
  try {
    const token = localStorage.getItem('login')
    ? JSON.parse(localStorage.getItem('login'))
    : ''
    const response = await axiosInstance.get(`/ipo-bid/${id}/`, {
      headers: {
        Authorization: `Bearer ${token?.access}`
      }
    })
    return response
  } catch (error) {
      return error
  }
}

export const editModifyIpoDataApi = async(id, data) => {
  try {
    const token = localStorage.getItem('login')
    ? JSON.parse(localStorage.getItem('login'))
    : ''
    const response = await axiosInstance.put(`/ipo-bid/${id}/`,data, {
      headers: {
        Authorization: `Bearer ${token?.access}`
      }
    })
    return response
  } catch (error) {
      return error
  }
}
export const deleteAccountApi = async (id, data) => {
  try {
    const token = localStorage.getItem('login')
      ? JSON.parse(localStorage.getItem('login'))
      : '';

    const response = await axiosInstance.delete(`/users/delete-account/`, {
      headers: {
        Authorization: `Bearer ${token?.access}`,
      },
      data: data,
    });

    return response;
  } catch (error) {
    return error;
  }
};