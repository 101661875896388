// REACT IMPORTS
import React, {
  lazy,
  Suspense,
  useCallback,
  useState,
  useEffect,
  useLayoutEffect,
  useRef
} from 'react' // Import React and Suspense for lazy loading

// REACT ROUTER IMPORTS
import {
  createBrowserRouter,
  RouterProvider,
  Link,
  useNavigate,
  useParams,
  useLocation
} from 'react-router-dom' // Essential Router components

// STYLES IMPORTS
import '../App.css'
import '../assets/css/loader.css'
import '../assets/css/navbar.css'
import '../assets/css/homepage.css'
import '../assets/css/footer.css'
import '../assets/css/contactusform.css'
import '../assets/css/authFormo.css'
import '../assets/css/profilePage.css'
import '../assets/css/ipoDetail.css'
import '../assets/css/brokerPage.css'
import '../assets/css/newsPage.css'
import '../assets/css/newsDetail.css'
import '../assets/css/about-us.css'
import '../assets/css/applyNow.css'
import '../assets/css/applied_ipo_list.css'
import '../assets/css/ipodetailDashboard.css'
import '../../node_modules/react-toastify/dist/ReactToastify.css'
import '../../node_modules/react-phone-number-input/style.css'
import '../../node_modules/aos/dist/aos.css'
import 'react-phone-number-input/style.css'

//images

//header
// import logo from '../assets/images/logo.png'
import logo from '../assets/images/header/logo_1.png'
import downArrow from '../assets/images/homepage/down.png'

// homepage
import heroImage from '../assets/images/homepage/hero_image.png'
import currentIpo from '../assets/images/homepage/current_ipo.svg'
import selectIcon from '../assets/images/homepage/select_icon.png'
import upcomingIpo from '../assets/images/homepage/upcoming_ipo.svg'
import listedIpo from '../assets/images/homepage/listed_ipo.svg'
import hyundaiIpo from '../assets/images/homepage/hyundai-india-logo.jpg.png'
import WaareeIpo from '../assets/images/homepage/Waaree.png.png'
import swiggyIpo from '../assets/images/homepage/swiggy-logo.png.png'
import newsHealth from '../assets/images/homepage/newshealth.png'
import newsJio from '../assets/images/homepage/newsjio.png'
import newsrelianceJio from '../assets/images/homepage/newsrelianceJio.png'
import newsRelliance from '../assets/images/homepage/newsrelliance.png'
import downNews from '../assets/images/homepage/down_news.svg'
import appStore from '../assets/images/homepage/appStore.png'
import playStore from '../assets/images/homepage/playStore.png'
import twoMobile from '../assets/images/homepage/twoMobile.png'
import brokerKotak from '../assets/images/homepage/brokerKotak.png'
import brokerUpstox from '../assets/images/homepage/brokerUpstox.jpg'
import brokerdhan from '../assets/images/homepage/brokerdhan.png'
import brokerzerodha from '../assets/images/homepage/brokerzerodha.png'
import ipoDefault from '../assets/images/homepage/ipoDefault.png'
import brokericici from '../assets/images/homepage/brokericici.jpg'
import brokerAngel from '../assets/images/homepage/brokerAngel.png'
import brokerpaisa5 from '../assets/images/homepage/broker5paisa.png'
import brokergroww from '../assets/images/homepage/brokerGroww.webp'
import brokerRight from '../assets/images/homepage/broker_right.png'
// import aboutUsHome from '../assets/images/homepage/aboutUsHome.png'
import aboutUsHome from '../assets/images/about_us/about_us_1.avif'
import getInTouchImg from '../assets/images/homepage/getInTouch.png'
import facebook from '../assets/images/footer/Link - facebook.png.png'
import Instagram from '../assets/images/footer/Link - Instagram Profile.png'
import Telegram from '../assets/images/footer/Link - Telegram Profile.png'
import Youtube from '../assets/images/footer/Link - Youtube Channel.png'
import twitter from '../assets/images/footer/Link - twitter.png.png'
import Whatsapp from '../assets/images/footer/Link - Whatsapp Profile.png'
import aboutUsSide from '../assets/images/about_us/about_us_side.webp'
import {
  IoClose,
  IoLogOut,
  IoDocumentText,
  IoCaretDownSharp
} from 'react-icons/io5'
import { IoIosLink } from 'react-icons/io'
import { MdOutlineDateRange, MdDelete  } from 'react-icons/md'
import { FiInstagram, FiLinkedin, FiMail } from 'react-icons/fi'
import ipoTrend from '../assets/images/about_us/ipoTrend.png'
import inverted_comma from '../assets/images/about_us/inverted_comma.png'
import { CiCamera } from 'react-icons/ci'
import { GiBull } from 'react-icons/gi'
import pdfImg from '../assets/pdf/nopreviewEvents.pdf'
// import no_preview from '../assets/images/homepage/no_preview.jpg'
import no_preview from '../assets/images/news/news.avif'
import googleImg from '../assets/images/contactUs/google.png'
import loginImg from '../assets/images/login/login.png'
import signUpimg from '../assets/images/login/sign_up.png'
import forgotPasswordimg from '../assets/images/login/forgot_password.png'
import verifyCodeImg from '../assets/images/login/verifyCode.png'
import setPasswordImg from '../assets/images/login/setPassword.png'
import profilePageImg from '../assets/images/login/profilePageImg.png'
import expert_1 from '../assets/images/about_us/testinomial_1.avif'
import expert_2 from '../assets/images/about_us/testinomial2.jpg'
import expert_3 from '../assets/images/about_us/testinomial3.jpg'

//icons
import { RiMenuAddLine } from 'react-icons/ri'
import {
  FaPlus,
  FaChevronLeft,
  FaChevronRight,
  FaMinus,
  FaUser,
  FaChevronDown,
  FaUserTie,
  FaCircleUser,
  FaCamera,
  FaEye,
  FaEyeSlash,
  FaXTwitter,
  FaFilePdf,
  FaThumbsUp,
  FaCheck,
  FaThumbsDown,
  FaArrowRight,
  FaArrowRightLong
} from 'react-icons/fa6'
import {
  FaRegUserCircle,
  FaFileDownload,
  FaLink,
  FaHandPointRight
} from 'react-icons/fa'

// store
import { configureStore, createSlice } from '@reduxjs/toolkit'

import { useDispatch, useSelector, Provider } from 'react-redux'
import ipoDataReducer from '../store/slice/ipoDataSlice'
import ipoDetailReducer from '../store/slice/ipoDetailSlice'
import NewsListReducer from '../store/slice/newsListSlice'
import NewsPageListReducer from '../store/slice/newsPageSlice'
import loginProfileReducer from '../store/slice/loginProfileSlice'
import ipoLiveDataReducer from '../store/slice/ipoLiveDataSlice.js'
import ipoUpcomingDataReducer from '../store/slice/ipoUpcomingDataSlice.js'
import ipoListedDataReducer from '../store/slice/ipoListedDataSlice.js'
import fagListReducer from '../store/slice/fagListSlice.js'
import appliedIpoListReducer from "../store/slice/appliedIpoListSlice.js"
import modifyIpoDataReducer from "../store/slice/modifyAppliedIpoSlice.jsx"

import axios from 'axios'
import {
  setIpoType,
  setIpoCategory,
  startLoading,
  stopLoading,
  setIpoData,
  setError,
  clearError
} from '../store/slice/ipoDataSlice'

import {
  setNewsData,
  NewsStartLoading,
  NewsStopLoading,
  setNewsError,
  clearNewsError
} from '../store/slice/newsListSlice'

import {
  setIpoDetail,
  startLoadingDetail,
  stopLoadingDetail,
  setErrorDetail,
  clearErrorDetail
} from '../store/slice/ipoDetailSlice'

import {
  setNewsPageData,
  NewsStartPageLoading,
  NewsStopPageLoading,
  setNewsPageError,
  setPreviousUrl,
  setNextUrl,
  setPage,
  setNewsPaginationLink,
  setNewsPageSize,
  setNewsPageNo
} from '../store/slice/newsPageSlice'

import {
  setLoginData,
  setProfileData,
  removeLoginData,
  removeProfileData
} from '../store/slice/loginProfileSlice.js'

import {
  setFaqData,
  startFaqLoading,
  stopFaqLoading,
  setFaqError,
  clearFaqError,
} from '../store/slice/fagListSlice.js'

import {
  setAppliedIpoData,
startappliedLoading,
stopappliedLoading,
setappliedError,
clearappliedError,
setappliednext,
setAppliedPaginationLink,
setappliedprevious,
setappliedPageSize,
setappliedIpoListPageNo,
}from '../store/slice/appliedIpoListSlice.js'

import {
  setModifiyAppliedIpoData
} from "../store/slice/modifyAppliedIpoSlice.jsx"

import {
  setliveIpoData,
  setLiveIpoType,
  setTypeFilter,
  startLiveLoading,
  stopLiveLoading,
  setLiveError,
  clearLiveError,
  setlivenext,
  setLivePaginationLink,
  setLiveprevious,
  setLivePageSize,
  setLivePageNo
} from '../store/slice/ipoLiveDataSlice.js'

import {
  setUpcomingIpoData,
  setUpcomingIpoType,
  setTypeUpcomingFilter,
  startUpcomingLoading,
  stopUpcomingLoading,
  setUpcomingError,
  clearUpcomingError,
  setUpcomingnext,
  setUpcomingprevious,
  setUpcomingPaginationLink,
  setUpcomingPageSize,
  setUpcomingPageNo
} from '../store/slice/ipoUpcomingDataSlice.js'

import {
  setListedIpoData,
  setListedIpoType,
  setTypeListedFilter,
  startListedLoading,
  stopListedLoading,
  setListedError,
  clearListedError,
  setListednext,
  setListedprevious,
  setListedPaginationLink,
  setListedPageSize,
  setListedPageNo
} from '../store/slice/ipoListedDataSlice.js'

import store from '../store/store'

// apis
import {
  getIpoList,
  getIpoDetail,
  getNewsList,
  getNewspage,
  getNewspageWise,
  getnewsDetails,
  getIpoLiveDataPage,
  getIpoLiveDataWise,
  addContactUs,
  faqListApi,

} from '../api/IposApi'
import {
  loginApi,
  setSignUpApi,
  getProfileData,
  setProfileDataApi,
  forgotPasswordApi,
  setNewPasswordApi,
  categoryListApi,
  addAccountApi,
  applyIpoApi,
  accountListApi,
  appliedIpoListApi,
  getModifyIpoDataApi,
  editModifyIpoDataApi,
  deleteAccountApi,
} from '../api/authApi'
import axiosInstance from '../api/axiosInstance'

//library form
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import ReactPaginate from 'react-paginate'
import CryptoJS from 'crypto-js'
import Modal from 'react-modal';
import Checkbox from "react-custom-checkbox";


import {
  getInTouchSchema,
  getInTouchFormFields,
  newsLetterSchema,
  newsLetterFormFields,
  loginSchema,
  deleteAccountSchema,
  loginFormFields,
  deleteAccountFormFields,
  registrationSchema,
  registrationFormFields,
  emailSchema,
  veriFyCodeSchema,
  setPasswordSchema,
  profileSchema,
} from '../components/FormSchema'
import AOS from 'aos'
import Select from 'react-select';

//common funtions

import {
  ScrollToTop,
  convertDateString,
  extractLink,
  removeLastNumberFromUrl,
  setPageParameter,
  brokersList,
  parseRegistrarDetail,
  isIOS,
  isSafari,
  isIOSAndSafari,
  isTodayOrBefore,
  extractWebsiteName,
  getToken,
  createHeaders,
  handleApiRequest,
  decrypt,
  checkUrl,
  generateToken,
  navigateToLogin,
  handleUnauthorized,
  formatDate,
  PATHS,
} from '../utils/utils'

import PhoneInput from 'react-phone-number-input'

import { ToastContainer, toast } from 'react-toastify' // Import Toastify
// COMPONENTS (Lazy-loaded)
const Header = lazy(() => import('../components/Header'))
const Footer = lazy(() => import('../components/Footer'))
const Loader = lazy(() => import('../components/Loader'))
const Button = lazy(() => import('../uiComponents/Button'))
// const IpoList = lazy(() => import('../components/IpoList'))
const SelectMainBoard = lazy(() => import('../components/SelectMainBoard'))
const Tags = lazy(() => import('../components/Tags'))
const IpoNewsList = lazy(() => import('../components/IpoNewsList'))
const TopBrokersList = lazy(() => import('../components/TopBrokersList'))
const Accordian = lazy(() => import('../components/Accordian'))
const GetInTouchForm = lazy(() => import('../components/GetInTouchForm'))
const Input = lazy(() => import('../components/Input'))
const ProfilePageInput = lazy(() => import('../components/ProfilePageInput'))
const TextArea = lazy(() => import('../components/TextArea'))
const NewsLetter = lazy(() => import('../components/NewsLetter'))
const Brokers = lazy(() => import('../components/Brokers'))
const IPOList2 = lazy(() => import('../components/IPOList2'))
const FilterListingType = lazy(() => import('../components/FilterListingType'))
const TabsList = lazy(() => import('../components/TabsList'))
const IpoDetail = lazy(() => import('../components/IpoDetail'))
const ContactUsForm = lazy(() => import('../components/ContactUsForm'))
const AuthFormo = lazy(() => import('../components/AuthFormo'))
const AuthFormTitle = lazy(() => import('../components/AuthFormTitle'))
const InputField = lazy(() => import('../components/InputField'))
const DeleteInputField =  lazy(() => import('../components/DeleteInputFiled.jsx'));
const OrLine = lazy(() => import('../components/OrLine'))
const BrokerCard = lazy(() => import('../components/BrokerCard'))
const PopularNews = lazy(() => import('../components/PopularNews'))
const IpoInnerList = lazy(() => import('../components/IpoInnerList'))
const FilterTypesInner = lazy(() => import('../components/FilterTypesInner'))
const SocialLinks = lazy(() => import('../components/SocialLinks'))
const Labels = lazy(() => import('../components/Labels.jsx'))
const ApplyButtons = lazy(() => import('../components/ApplyButtons.jsx'))

//pages
const HomePage = lazy(() => import('../pages/HomePage'))
const IPODashboardPage = lazy(() => import('../pages/IPODashboardTable.jsx'))
const IPODetailsPage = lazy(() => import('../pages/IPODetailsPage.jsx'))




const ContactUs = lazy(() => import('../pages/ContactUs'))
const Login = lazy(() => import('../pages/Login'))
const SignUp = lazy(() => import('../pages/SignUp'))
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'))
const VerifyCode = lazy(() => import('../pages/VerifyCode'))
const SetPassWord = lazy(() => import('../pages/SetPassWord'))
const Profilepage = lazy(() => import('../pages/ProfilePage'))
const BrokerPage = lazy(() => import('../pages/BrokerPage'))
const NewsPage = lazy(() => import('../pages/NewsPage'))
const NewsDetail = lazy(() => import('../pages/NewsDetail'))
const AboutUs = lazy(() => import('../pages/AboutUs.jsx'))
const ListedIpoPage = lazy(() => import('../pages/ListedIpoPage.jsx'))
const UpcomingIpoPage = lazy(() => import('../pages/UpcomingIpoPage.jsx'))
const CurrentIpoPage = lazy(() => import('../pages/CurrentIpoPage.jsx'))
const BrokerDetail = lazy(() => import('../pages/BrokerDetail.jsx'))
const FAQsPage = lazy(() => import('../pages/FAQs.jsx'))
const TermsAndConditionsPage = lazy(() => import('../pages/TermsAndConditions.jsx') )
const PrivacyPolicyPage = lazy(() => import('../pages/PrivacyPolicyPage.jsx'))
const PrivacyPolicyIpoTrend = lazy(() => import('../pages/PrivacyPolicyIpoTrend.jsx'))
const ApplyPage = lazy(() => import('../pages/ApplyPage.jsx'))
const AppliedIpoList = lazy(() => import('../pages/AppliedIpoList.jsx'))
const ModifyAppliedIpo = lazy(() => import('../pages/ModifyAppliedIpo.jsx'))
const DeleteAccount = lazy(() => import('../pages/DeleteAccount.jsx'))

// EXPORTS
// Exporting all necessary React and Router components
export {
  React,
  lazy,
  Suspense,
  createBrowserRouter,
  RouterProvider,
  Link,
  useCallback,
  useState,
  useEffect,
  useLayoutEffect,
  ReactPaginate,
  CryptoJS,
  useRef,
  z,
  zodResolver,
  useForm,
  getInTouchSchema,
  profileSchema,
  setPasswordSchema,
  registrationSchema,
  getInTouchFormFields,
  newsLetterSchema,
  newsLetterFormFields,
  loginSchema,
  deleteAccountSchema,
  loginFormFields,
  deleteAccountFormFields,
  configureStore,
  axios,
  setIpoType,
  setIpoCategory,
  startLoading,
  stopLoading,
  useDispatch,
  useSelector,
  createSlice,
  store,
  Provider,
  getIpoList,
  loginApi,
  setIpoData,
  setError,
  clearError,
  axiosInstance,
  useNavigate,
  useParams,
  getIpoDetail,
  getNewsList,
  getNewspage,
  getNewspageWise,
  getnewsDetails,
  getIpoLiveDataPage,
  getIpoLiveDataWise,
  addContactUs,
  getProfileData,
  applyIpoApi,
  setProfileDataApi,
  forgotPasswordApi,
  setNewPasswordApi,
  categoryListApi,
  accountListApi,
  appliedIpoListApi,
  getModifyIpoDataApi,
  editModifyIpoDataApi,
  deleteAccountApi,
  ipoDataReducer,
  ipoDetailReducer,
  NewsListReducer,
  NewsPageListReducer,
  loginProfileReducer,
  ipoLiveDataReducer,
  ipoUpcomingDataReducer,
  ipoListedDataReducer,
  fagListReducer,
  appliedIpoListReducer,
  modifyIpoDataReducer,
  setNewsData,
  NewsStartLoading,
  NewsStopLoading,
  setNewsError,
  clearNewsError,
  setIpoDetail,
  startLoadingDetail,
  stopLoadingDetail,
  setErrorDetail,
  clearErrorDetail,
  ContactUs,
  Login,
  HomePage,
  IPODashboardPage,
  IPODetailsPage,
  SignUp,
  ForgotPassword,
  VerifyCode,
  SetPassWord,
  registrationFormFields,
  emailSchema,
  veriFyCodeSchema,
  Profilepage,
  BrokerPage,
  NewsPage,
  setNewsPageData,
  NewsStartPageLoading,
  NewsStopPageLoading,
  setNewsPageError,
  setPage,
  setNextUrl,
  setPreviousUrl,
  ToastContainer,
  toast,
  setSignUpApi,
  faqListApi,
  addAccountApi,
  PhoneInput,
  useLocation,
  NewsDetail,
  PopularNews,
  AOS,
  Select,
  setLoginData,
  setProfileData,
  removeLoginData,
  removeProfileData,
  setFaqData,
  startFaqLoading,
  stopFaqLoading,
  setFaqError,
  clearFaqError,
  setAppliedIpoData,
  startappliedLoading,
  stopappliedLoading,
  setappliedError,
  clearappliedError,
  setappliednext,
  setAppliedPaginationLink,
  setappliedprevious,
  setappliedPageSize,
  setappliedIpoListPageNo,
  setModifiyAppliedIpoData,
  AboutUs,
  ModifyAppliedIpo,
  DeleteAccount,
  ListedIpoPage,
  UpcomingIpoPage,
  CurrentIpoPage,
  setliveIpoData,
  setLiveIpoType,
  setTypeFilter,
  startLiveLoading,
  stopLiveLoading,
  setLiveError,
  clearLiveError,
  setlivenext,
  setLivePaginationLink,
  setLiveprevious,
  setLivePageSize,
  setLivePageNo,
  setUpcomingIpoData,
  setUpcomingIpoType,
  setTypeUpcomingFilter,
  startUpcomingLoading,
  stopUpcomingLoading,
  setUpcomingError,
  clearUpcomingError,
  setUpcomingnext,
  setUpcomingprevious,
  setListedIpoData,
  setListedIpoType,
  setTypeListedFilter,
  startListedLoading,
  stopListedLoading,
  setListedError,
  clearListedError,
  setListednext,
  setListedprevious,
  setUpcomingPaginationLink,
  setUpcomingPageSize,
  setUpcomingPageNo,
  setListedPaginationLink,
  setListedPageSize,
  setListedPageNo,
  setNewsPaginationLink,
  setNewsPageSize,
  setNewsPageNo,
  BrokerDetail,
  TermsAndConditionsPage,
  PrivacyPolicyPage,
  PrivacyPolicyIpoTrend,
  ApplyPage,
  AppliedIpoList,
  FAQsPage,
  Modal,
  Checkbox,
}

// Exporting all lazy-loaded components together
export const Components = {
  Header,
  Footer,
  Loader,
  Button,
  // IpoList,
  SelectMainBoard,
  Tags,
  IpoNewsList,
  TopBrokersList,
  Accordian,
  GetInTouchForm,
  Input,
  TextArea,
  NewsLetter,
  Brokers,
  IPOList2,
  FilterListingType,
  TabsList,
  IpoDetail,
  ContactUsForm,
  AuthFormo,
  AuthFormTitle,
  InputField,
  DeleteInputField,
  OrLine,
  ProfilePageInput,
  BrokerCard,
  IpoInnerList,
  FilterTypesInner,
  SocialLinks,
  brokersList,
  Labels,
  ApplyButtons
}

export const Images = {
  logo,
  downArrow,
  heroImage,
  currentIpo,
  selectIcon,
  upcomingIpo,
  listedIpo,
  hyundaiIpo,
  WaareeIpo,
  swiggyIpo,
  newsHealth,
  newsJio,
  newsrelianceJio,
  newsRelliance,
  downNews,
  appStore,
  playStore,
  twoMobile,
  brokerKotak,
  brokerUpstox,
  brokerdhan,
  brokerzerodha,
  brokericici,
  brokerAngel,
  brokerpaisa5,
  brokergroww,
  brokerRight,
  aboutUsHome,
  getInTouchImg,
  facebook,
  Instagram,
  Telegram,
  Youtube,
  twitter,
  Whatsapp,
  pdfImg,
  no_preview,
  googleImg,
  loginImg,
  signUpimg,
  forgotPasswordimg,
  verifyCodeImg,
  setPasswordImg,
  profilePageImg,
  aboutUsSide,
  ipoTrend,
  expert_1,
  expert_2,
  expert_3,
  inverted_comma,
  ipoDefault
}

export const icons = {
  RiMenuAddLine,
  FaPlus,
  FaMinus,
  FaUser,
  FaFilePdf,
  FaChevronLeft,
  FaChevronRight,
  FaRegUserCircle,
  FaFileDownload,
  IoLogOut,
  IoDocumentText,
  IoClose,
  FaChevronDown,
  FaUserTie,
  FaCircleUser,
  CiCamera,
  FaCamera,
  FaEye,
  FaEyeSlash,
  FaXTwitter,
  FiInstagram,
  IoCaretDownSharp,
  IoIosLink,
  FiLinkedin,
  FiMail,
  MdOutlineDateRange,
  MdDelete,
  FaThumbsUp,
  FaThumbsDown,
  FaArrowRight,
  FaCheck,
  FaLink,
  FaHandPointRight,
  GiBull,
  FaArrowRightLong
}

export const commonFunctions = {
  ScrollToTop,
  convertDateString,
  extractLink,
  removeLastNumberFromUrl,
  parseRegistrarDetail,
  setPageParameter,
  isIOS,
  isSafari,
  isIOSAndSafari,
  isTodayOrBefore,
  extractWebsiteName,
  getToken,
  createHeaders,
  handleApiRequest,
  decrypt,
  checkUrl,
  generateToken,
  navigateToLogin,
  formatDate,
  PATHS
}
