import { createSlice } from '@reduxjs/toolkit'

const appliedIpoListSlice = createSlice({
  name: 'appliedIpoListSlice',
  initialState: {
    appliedIpoList: [],
    appliedloading: true,
    appliederror: null,
    appliednext: '',
    appliedprevious: '',
    AppliedPaginationLink: '',
    appliedPageSize: 1,
    appliedIpoListPageNo: sessionStorage.getItem("appliedIpoListPageNo") ? +JSON.parse(sessionStorage.getItem("appliedIpoListPageNo")) : 1,
  },
  reducers: {
    setAppliedIpoData: (state, action) => {
      state.appliedIpoList = action.payload // Fix: Updated the correct key
    },
    startappliedLoading: state => {
      state.appliedloading = true
    },
    stopappliedLoading: state => {
      state.appliedloading = false
    },
    setappliedError: (state, action) => {
      state.appliederror = action.payload
    },
    setappliednext: (state, action) => {
      state.appliednext = action.payload
    },
    setappliedprevious: (state, action) => {
      state.appliedprevious = action.payload
    },
    setAppliedPaginationLink: (state, action) => {
      state.AppliedPaginationLink = action.payload
    },
    setappliedPageSize: (state, action) => {
      state.appliedPageSize = action.payload
    },
    setappliedIpoListPageNo: (state, action) => {
      state.appliedIpoListPageNo = action.payload
      sessionStorage.setItem("appliedIpoListPageNo", JSON.stringify(action.payload))
    }
  }
})

export const {
  setAppliedIpoData,
  startappliedLoading,
  stopappliedLoading,
  setappliedError,
  clearappliedError,
  setappliednext,
  setAppliedPaginationLink,
  setappliedprevious,
  setappliedPageSize,
  setappliedIpoListPageNo
} = appliedIpoListSlice.actions

export default appliedIpoListSlice.reducer
