import { configureStore, ipoDataReducer, ipoDetailReducer, ipoLiveDataReducer, loginProfileReducer, NewsListReducer, NewsPageListReducer, ipoUpcomingDataReducer, ipoListedDataReducer, fagListReducer, appliedIpoListReducer, modifyIpoDataReducer } from '../utils/npm'

const store = configureStore({
  reducer: {
    ipoData: ipoDataReducer,
    ipoDetail: ipoDetailReducer,
    newsList: NewsListReducer,
    newsPage: NewsPageListReducer,
    loginProfile: loginProfileReducer,
    ipoLiveData: ipoLiveDataReducer,
    ipoUpcomingData: ipoUpcomingDataReducer,
    ipoListedData: ipoListedDataReducer,
    fagListData: fagListReducer,
    appliedIpoListData: appliedIpoListReducer,
    modifyIpoData: modifyIpoDataReducer
  }
})

export default store
