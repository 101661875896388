import {
  useDispatch,
  useSelector,
  useParams,
  React,
  useState,
  useEffect,
  getIpoDetail,
  startLoadingDetail,
  stopLoadingDetail,
  setIpoDetail,
  Images,
  commonFunctions,
  Components,
  icons,
  Select,
  categoryListApi,
  accountListApi,
  useNavigate,
  Modal,
  addAccountApi,
  toast,
  Checkbox,
  applyIpoApi,
  getModifyIpoDataApi,
  setModifiyAppliedIpoData,
  useLocation,
  editModifyIpoDataApi,
  useLayoutEffect
} from '../utils/npm'
const { handleUnauthorized } = commonFunctions
const { Loader } = Components
const { IoClose, FaCheck } = icons
const ModifyAppliedIpo = () => {
    const editData = useSelector(state => state.modifyIpoData.data)
    const [formValues, setFormValues] = useState({
        selectedCategory: null,
        selectedAccount: null,
        price: '',
        selectedLotSize: null,
        privacyPolicy: false
    })
    const [loading, setLoader] = useState(false)
  const [applyErrors, setApplyErrors] = useState({})
  const [categoryList, setCategoryList] = useState([])
  const [categoryLoader, setcategoryLoader] = useState(false)
  const [accountLoader, setAccountLoader] = useState(false)
  const [formSubmitLoader, setFormSubmitLoader] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [ipoId, setIpoId] = useState('')
  const [ipoDetail, setIpoDetails] = useState('')
  const [lotSize, setLotSize] = useState([])
  const [selectedLotSize, setSelectedLotSize] = useState(null)
  const [accountList, setAccountList] = useState([])
  const [accountType, setAccountType] = useState([
    {
      id: '1',
      value: 'CDSL'
    },
    {
      id: '2',
      value: 'NSDL'
    }
  ])
  const [bankDetailFormPrint, setBankDetailFormPrint] = useState([
    {
      id: '1',
      value: 'YES'
    },
    {
      id: '2',
      value: 'NO'
    }
  ])
  const [addAccountValue, setAddAccountValue] = useState({
    AccountType: accountType[0].value,
    applicantName: '',
    panNumber: '',
    dpId: '',
    clientId: '',
    upiId: '',
    bankDetailFormPrint: bankDetailFormPrint[0].value,
    bankName: '',
    bankAccountNumber: ''
  })
  const [priceValue, setPriceValue] = useState(0)

  const [error, setError] = useState({
    AccountType: '',
    applicantName: '',
    panNumber: '',
    dpId: '',
    clientId: '',
    upiId: '',
    bankDetailFormPrint: '',
    bankName: '',
    bankAccountNumber: ''
  })
  const [modalIsOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const getDetail = async () => {
    setLoader(true)
    try {
      dispatch(startLoadingDetail())
      if(Object.keys(editData)?.length > 0){
        await categoryLists(editData)
        setIpoDetails(editData)
        setIpoId(editData.ipo)
        dispatch(setModifiyAppliedIpoData(editData))
      }else{
        const response = await getModifyIpoDataApi(id)
        if (response && response.meta && response.meta.status_code === 200) {
          await categoryLists(response.data)
          setIpoDetails(response.data)
          setIpoId(response.data.ipo)
          dispatch(setModifiyAppliedIpoData(response.data))
        } else {
          console.error('Failed to fetch IPO details.')
        }
      }
    
    } catch (error) {
      console.error('Error fetching IPO details:', error)
    } finally {
      dispatch(stopLoadingDetail())
    }
    setLoader(false)
  }
  const categoryLists = async data => {
    setcategoryLoader(true)
    try {
      let response = await categoryListApi(data.ipo)
      if (response.meta.status_code === 200) {
        let datas = response.data.results.map((ele, ind) => {
          let obj = { ...ele }
          obj.label = ele?.name
          obj.value = ele?.id
          return obj
        })
        setCategoryList(datas)
        let categoryData = datas.find(ele => ele.id === data?.ipo_category)
        if (categoryData) {
          accountLists(data, categoryData.id, categoryData)
        }
      } else {
        return await handleUnauthorized(
          categoryListApi,
          dispatch,
          navigate,
          data.ipo
        )
      }
    } catch (error) {
      console.log(error)
    }
    setcategoryLoader(false)
  }

  const accountLists = async (data, id, categoryData) => {
    setAccountLoader(true)
    try {
      let response = await accountListApi(data.ipo, id)
      let obj = {
        label: 'Add Account',
        value: 'Add Account'
      }
      if (response.meta.status_code === 200) {
        let datas = []
        response.data.results.forEach((ele, ind) => {
          let objs = { ...ele }
          objs.label = ele?.full_name
          objs.value = ele?.pan_number
          datas.push(objs)
        })
        let selectedAccounts = []
        data.bid_accounts.forEach((ele, ind) => {
          let objs = { ...ele }
          objs.label = ele?.full_name
          objs.value = ele?.pan_number
          datas.push(objs)
          selectedAccounts.push(objs)
        })
        datas.push(obj)
        setAccountList(datas)
        const dataLot = Array.from(
          { length: categoryData.lot_end - categoryData.lot_start + 1 },
          (_, index) => {
            const values = categoryData.lot_start + index
            return { label: values, value: values }
          }
        )
        setLotSize(dataLot)
        setFormValues({
          ...formValues,
          selectedAccount: selectedAccounts,
          selectedCategory: categoryData,
          price: (data.min_value)?.toString(),
          selectedLotSize: dataLot[0],
          privacyPolicy: true
        })
        
        setPriceValue(dataLot[0].value * +data.min_value * data?.bid_lot)

      } else if (
        response?.meta?.validations?.[0]?.error?.[0] ===
        'No DematAccount matches the given query.'
      ) {
        let obj = {
          label: 'Add Account',
          value: 'Add Account'
        }
        setAccountList([obj])
      } else {
        return await handleUnauthorized(
          accountListApi,
          dispatch,
          navigate,
          ipoId
        )
      }
    } catch (error) {
      let obj = {
        label: 'Add Account',
        value: 'Add Account'
      }
      setAccountList([obj])
    }
    setAccountLoader(false)
  }

  function closeModal () {
    setIsOpen(false)
  }

  const handleChangeDetailAccount = e => {
    const { name, value } = e.target
    setAddAccountValue({ ...addAccountValue, [name]: value })
  }

  const handleChange = async (name, value) => {
    if (name === 'selectedCategory') {
      const data = Array.from(
        { length: value.lot_end - value.lot_start + 1 },
        (_, index) => {
          const values = value.lot_start + index
          return { label: values, value: values }
        }
      )
      setLotSize(data)

      //   if (ipoDetailData?.price_range) {
      // let prices = ipoDetailData.price_range
      //   .split('-')?.[0]
      //   ?.replace('₹', '')
      //   ?.trim()
      let datas = { ...formValues }
      datas[name] = value
      datas.price = editData.min_value
      datas.selectedLotSize = data[0]
      datas.selectedAccount = null
      setFormValues(datas)
      setPriceValue(data[0].value * +editData.min_value * editData?.bid_lot)
      //   }
      //   else {
      //     console.log('aksjdhjasd')
      //     setFormValues(prev => ({
      //       ...prev,
      //       [name]: value,
      //       selectedAccount: null
      //     }))
      //   }

      await accountLists(editData, value.id, formValues?.selectedCategory)
    } else if (name === 'selectedAccount') {
      let findLabel = value.find((ele, ind) => ele.label === 'Add Account')
      if (findLabel?.label === 'Add Account') {
        setIsOpen(true)
      } else {
        setFormValues(prev => ({
          ...prev,
          [name]: value
        }))
      }
    } else {
      setFormValues(prev => ({
        ...prev,
        [name]: value
      }))
    }
    if (name === 'price') {
      if (formValues?.selectedLotSize?.value) {
        setPriceValue(
          formValues?.selectedLotSize?.value * +value * editData?.bid_lot
        )
      }
    }
    if (name === 'selectedLotSize') {
      if (formValues?.price !== NaN) {
        setPriceValue(value.value * +formValues?.price * editData?.bid_lot)
      }
    }
  }

  const validateForm = () => {
    const requiredFields = {
      AccountType: 'Account Type is required.',
      applicantName: 'Applicant Name is required.',
      panNumber: 'PAN Number is required.',
      clientId: 'Client ID is required.',
      upiId: 'UPI ID is required.',
      bankDetailFormPrint: 'Please select an option.'
    }

    let errors = {}

    Object.keys(requiredFields).forEach(field => {
      if (!addAccountValue[field]?.trim()) {
        errors[field] = requiredFields[field]
      }
    })

    if (
      addAccountValue.clientId &&
      (addAccountValue.clientId.length < 16 ||
        addAccountValue.clientId.length > 16) &&
      addAccountValue.AccountType === 'CDSL'
    ) {
      errors.clientId = 'Client Id must be 16 characters.'
    } else if (
      addAccountValue.clientId &&
      (addAccountValue.clientId.length < 8 ||
        addAccountValue.clientId.length > 8) &&
      addAccountValue.AccountType === 'NSDL'
    ) {
      errors.clientId = 'Client Id must be 8 characters.'
    }
    if (
      addAccountValue.panNumber &&
      !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(addAccountValue.panNumber)
    ) {
      errors.panNumber = 'Invalid PAN Number format.'
    }
    if (
      addAccountValue.AccountType === 'NSDL' &&
      !addAccountValue.dpId?.trim()
    ) {
      errors.dpId = 'DP ID is required for NSDL accounts.'
    }
    if (
      addAccountValue.dpId?.trim()?.length !== 8 &&
      addAccountValue.dpId?.trim()?.length > 0
    ) {
      errors.dpId = 'The DP Id must be 8 characters.'
    }
    if (
      addAccountValue.upiId &&
      !/^[\w.-]+@[a-zA-Z]+$/.test(addAccountValue.upiId)
    ) {
      errors.upiId = 'Invalid UPI ID format.'
    }
    if (addAccountValue.bankDetailFormPrint === 'YES') {
      if (!addAccountValue.bankName?.trim()) {
        errors.bankName = 'Bank Name is required.'
      }
      if (!addAccountValue.bankAccountNumber?.trim()) {
        errors.bankAccountNumber = 'Bank Account Number is required.'
      } else if (!/^\d+$/.test(addAccountValue.bankAccountNumber)) {
        errors.bankAccountNumber = 'Invalid Bank Account Number format.'
      }
    }
    setError(errors)
    return Object.keys(errors).length === 0
  }

  const validateApplyForm = () => {
    const newErrors = {}
    if (!formValues.selectedCategory) {
      newErrors.selectedCategory = 'Category is required.'
    }
    if (formValues.selectedCategory && !formValues.selectedAccount) {
      newErrors.selectedAccount = 'Account is required.'
    }
    if (((formValues.price).trim())?.length ===0) {
      newErrors.price = 'Price is required.'
    } else if (isNaN(Number(+formValues.price))) {
      newErrors.price = 'Price must be a valid number.'
    } else if (ipoDetail.ipo_min_value > +formValues.price) {
      newErrors.price = `Price must be greater than or Equal to ${formValues.price}`
    } else if (ipoDetail.ipo_max_value < +formValues.price) {
      newErrors.price = `Price must be lower than or Equal to ${formValues.price} `
    }
    if (!formValues.selectedLotSize) {
      newErrors.selectedLotSize = 'Lot size is required.'
    }
    if (!formValues.privacyPolicy) {
      newErrors.privacyPolicy = 'You must accept the privacy policy.'
    }

    setApplyErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleFormSubmit = async e => {
    e.preventDefault()
    setFormLoading(true)
    if (validateForm()) {
      try {
        let data = {
          account_type: addAccountValue.AccountType,
          full_name: addAccountValue.applicantName,
          pan_number: addAccountValue.panNumber,
          upi_id: addAccountValue.upiId,
          back_account_number: null,
          bank_name: ''
        }

        if (data.account_type === 'NSDL') {
          data.client_id_for_nsdl = addAccountValue.clientId
        } else {
          data.client_id_for_cdsl = addAccountValue.clientId
        }
        if (addAccountValue.AccountType === 'NSDL') {
          data.dpid = addAccountValue.dpId
        }
        if (addAccountValue.bankDetailFormPrint === 'YES') {
          data.back_account_number = addAccountValue.bankAccountNumber
          data.bank_name = addAccountValue.bankName
        }
        data = JSON.stringify(data)
        const response = await addAccountApi(data)
        if (response.meta.status_code === 409) {
          const errorMessages = Object.values(
            response?.meta?.validations?.[0] || {}
          )
            .map(
              error => `<p classname='mb-0 text-sm'>${error}</p> </br>` // Wrap each error in a <p> tag
            )
            .join('')
          toast.error(
            <div
              className='flex flex-col'
              dangerouslySetInnerHTML={{ __html: errorMessages }}
            ></div>
          )
        } else if (
          response.meta.status_code === 200 ||
          response.meta.status_code === 201
        ) {
          accountLists(editData, formValues.selectedCategory.id, formValues.selectedCategory)
          setAddAccountValue({
            ...addAccountValue,
            AccountType: accountType[0].value,
            applicantName: '',
            panNumber: '',
            dpId: '',
            clientId: '',
            upiId: '',
            bankDetailFormPrint: bankDetailFormPrint[0].value,
            bankName: '',
            bankAccountNumber: ''
          })
          setError({
            ...error,
            AccountType: '',
            applicantName: '',
            panNumber: '',
            dpId: '',
            clientId: '',
            upiId: '',
            bankDetailFormPrint: '',
            bankName: '',
            bankAccountNumber: ''
          })
          toast.success('Account Added Successfully.')
          //   accountLists(editData, )
          setIsOpen(false)
          //   if (accountList.length === 0) accountLists()
        } else {
          toast.error('Something Went Wrong.')
        }
      } catch (error) {
        toast.error('Something Went Wrong.')
      }
    } else {
      console.log('Validation Failed', error)
    }
    setFormLoading(false)
  }

  const applyIpo = async e => {
    setFormSubmitLoader(true)
    e.preventDefault()
    if (validateApplyForm()) {
      let data = {
        ipo: editData.ipo,
        ipo_category: formValues.selectedCategory.id
      }
      let bidAccounts = formValues.selectedAccount.map((ele, ind) => {
        let obj = {
          demat_account: ele?.demat_account || ele?.id,
        //   id: ele?.id,
          ipo_price: (formValues.price)?.toString(),
          ipo_lots: (formValues.selectedLotSize.value)?.toString(),
          ipo_qty: (formValues.selectedLotSize.value * ipoDetail.bid_lot)?.toString(),
          ipo_total_amount:(
            formValues.selectedLotSize.value *
            ipoDetail.bid_lot *
            +formValues.price)?.toString()
        }
        if(ele?.demat_account){
            obj.id = ele?.id
        }
        return obj
      })
      data.bid_accounts = bidAccounts
      data = JSON.stringify(data)
      try {
        let response = await editModifyIpoDataApi(editData.id, data)
        if (response.meta.status_code === 401) {
          return await handleUnauthorized(applyIpo, dispatch, navigate)
        } else if (
          response.meta.status_code === 200 ||
          response.meta.status_code === 201
        ) {
          toast.success('Mandate Request Modified Successfully.')
          navigate("/appliedIpo")
        //  await accountLists(data, formValues.categoryData.id, formValues.categoryData)
         return
        } else if (response.meta.status_code === 400) {
          const errorMessages = Object.values(
            response?.meta?.validations?.[0] || {}
          )
            .map(error => `<p classname='mb-0 text-sm'>${error}</p> </br>`)
            .join('')
          toast.error(
            <div
              className='flex flex-col'
              dangerouslySetInnerHTML={{ __html: errorMessages }}
            ></div>
          )
        }
      } catch (error) {
        console.log(error)
        toast.error('Something Went Wrong.')
      }
    } else {
      console.log('Validation Errors:', applyErrors)
    }
    setFormSubmitLoader(false)
  }
  useLayoutEffect(() => {
    getDetail()
  }, [])

  return (
    <div className={`container custom-width-container `} >
      <>
       {loading ? <div className='flex justify-center'><Loader /></div> : <><div className='ipo-card-inner my-10 px-5 lg:px-10 apply_now_sec'>
          <div className='row details-page-card-grid apply_now_part'>
            <h2 className='text-xl md:text-2xl lg:text-3xl font-medium'>
              {editData?.company_name}
            </h2>
            <form onSubmit={applyIpo}>
              <div className='flex flex-wrap w-full'>
                <div className='w-full pb-5'>
                  <label className='text-sm'>Category</label>
                  <Select
                    name='selectedCategory'
                    value={formValues.selectedCategory}
                    onChange={value => {
                      handleChange('selectedCategory', value)
                    }}
                    className='apply_now_select'
                    options={categoryList}
                    placeholder='Select Category...'
                    isLoading={categoryLoader}
                    isDisabled={true} 
                  />
                  {applyErrors.selectedCategory && (
                    <p className='text-red-600 text-sm'>
                      {applyErrors.selectedCategory}
                    </p>
                  )}
                </div>
                {formValues.selectedCategory && (
                  <div className='flex flex-wrap md:justify-between w-full'>
                    <div
                      className={`w-full ${
                        formValues?.selectedAccount?.pan_number
                          ? 'md:w-[48%]'
                          : ''
                      }  pb-5`}
                    >
                      <label className='text-sm'>Add Account</label>
                      <Select
                        value={formValues.selectedAccount}
                        className='apply_now_select'
                        name='selectedAccount'
                        isLoading={accountLoader}
                        onChange={value =>
                          handleChange('selectedAccount', value)
                        }
                        options={accountList}
                        placeholder='Add Account...'
                        isMulti
                      />
                      {applyErrors.selectedAccount && (
                        <p className='text-red-600 text-sm'>
                          {applyErrors.selectedAccount}
                        </p>
                      )}
                    </div>
                    {formValues?.selectedAccount?.pan_number && (
                      <div className='w-full md:w-[48%] pb-5'>
                        <label className='text-sm'>Pan Number</label>
                        <p className='w-full applynowInput py-2 my-0 flex items-center'>
                          {formValues.selectedAccount.pan_number}
                        </p>
                      </div>
                    )}
                  </div>
                )}
                {formValues.selectedCategory && (
                  <>
                    <div className='flex flex-wrap w-full justify-between'>
                      <div className='w-[49%] pb-5'>
                        <label className='text-sm'>Price</label>
                        <input
                          type='number'
                          name='price'
                          className='w-full applynowInput '
                          placeholder='Price'
                          value={formValues.price}
                          onChange={e => {
                            handleChange('price', e.target.value)
                          }}
                        />
                        {applyErrors.price && (
                          <p className='text-red-600 text-sm'>
                            {applyErrors.price}
                          </p>
                        )}
                      </div>
                      <div className='w-[49%] pb-5'>
                        <label className='text-sm'>Lot Size</label>
                        <Select
                          name='selectedLotSize'
                          value={formValues.selectedLotSize}
                          onChange={value =>
                            handleChange('selectedLotSize', value)
                          }
                          className='apply_now_select'
                          options={lotSize}
                          placeholder='Lot Size...'
                        />
                        {applyErrors.selectedLotSize && (
                          <p className='text-red-600 text-sm'>
                            {applyErrors.selectedLotSize}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className=' w-full pb-3'>
                      <div className='apply_form_check'>
                        <Checkbox
                          icon={<FaCheck />}
                          name='privacyPolicy'
                          checked={formValues.privacyPolicy}
                          onChange={value =>
                            handleChange('privacyPolicy', value)
                          }
                          style={{ cursor: 'pointer' }}
                          labelStyle={{
                            marginLeft: 5,
                            userSelect: 'none'
                          }}
                          label='I agree to the Privacy Policy'
                        />
                      </div>
                      {applyErrors.privacyPolicy && (
                        <p className='text-red-600 text-sm'>
                          {applyErrors.privacyPolicy}
                        </p>
                      )}
                    </div>
                    {
                      <div className=' w-full pb-3 pt-5'>
                        <label className='text-sm'>Total Amount</label>
                        <div className='w-full applynowInput py-2 my-0 flex items-center'>
                          ₹{' '}
                          {priceValue.toLocaleString() !== 'NaN' &&
                            `${priceValue.toLocaleString()}`}
                        </div>
                      </div>
                    }
                  </>
                )}
                <button
                  className='auth_submit_view_more mt-5'
                  type='submit'
                  disabled={formSubmitLoader}
                >
                  {formSubmitLoader ? 'Applying...' : 'Apply'}
                </button>
              </div>
            </form>
          </div>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className={'account_add_modal'}
          >
            <button onClick={closeModal} className='close_modal_add_account'>
              <IoClose className='text-2xl text-gray-500' />{' '}
            </button>
            <h2 className='font-medium text-2xl'>Add Account</h2>
            <form className='add_account_form' onSubmit={handleFormSubmit}>
              <div className='pt-5'>
                <label htmlFor='' className='text-sm'>
                  Select Account Type
                </label>
                <div className='flex items-center'>
                  {accountType?.map((ele, ind) => {
                    return (
                      <div className='flex me-5 mt-3' key={ind}>
                        <input
                          type='radio'
                          className='accountTypeRadio me-2'
                          value={ele.value}
                          name='AccountType'
                          checked={addAccountValue.AccountType === ele.value}
                          key={ind}
                          onChange={e => handleChangeDetailAccount(e)}
                        />
                        <p className='text-sm'>{ele.value}</p>
                      </div>
                    )
                  })}
                </div>
                {error.AccountType && (
                  <p className='error_form_add_account text-red-600 text-sm pt-2 ps-1'>
                    {error.AccountType}
                  </p>
                )}
              </div>
              <div className='flex flex-wrap md:justify-between'>
                <div className='pt-5 w-full md:w-[48%]'>
                  <label htmlFor='' className='text-sm'>
                    Allicant Name
                  </label>
                  <input
                    type='text'
                    value={addAccountValue.applicantName}
                    name='applicantName'
                    className='mt-2'
                    onChange={handleChangeDetailAccount}
                  />
                  {error.applicantName && (
                    <p className='error_form_add_account text-red-600 text-sm pt-2 ps-1'>
                      {error.applicantName}
                    </p>
                  )}
                </div>
                <div className='pt-5 w-full md:w-[48%]'>
                  <label htmlFor='' className='text-sm'>
                    Pan Number
                  </label>
                  <input
                    type='text'
                    value={addAccountValue.panNumber}
                    name='panNumber'
                    className='mt-2'
                    onChange={handleChangeDetailAccount}
                  />
                  {error.panNumber && (
                    <p className='error_form_add_account text-red-600 text-sm pt-2 ps-1'>
                      {error.panNumber}
                    </p>
                  )}
                </div>
              </div>
              {addAccountValue.AccountType === 'NSDL' && (
                <div className='pt-5'>
                  <label htmlFor='' className='text-sm'>
                    DP ID
                  </label>
                  <input
                    type='text'
                    value={addAccountValue.dpId}
                    name='dpId'
                    className='mt-2'
                    onChange={handleChangeDetailAccount}
                  />
                  {error.dpId && (
                    <p className='error_form_add_account text-red-600 text-sm pt-2 ps-1'>
                      {error.dpId}
                    </p>
                  )}
                </div>
              )}
              <div className='flex flex-wrap md:justify-between'>
                <div className='pt-5 w-full md:w-[48%]'>
                  <label htmlFor='' className='text-sm'>
                    Client Id / Beneficiary Number
                  </label>
                  <input
                    type='text'
                    value={addAccountValue.clientId}
                    name='clientId'
                    className='mt-2'
                    onChange={handleChangeDetailAccount}
                  />
                  {error.clientId && (
                    <p className='error_form_add_account text-red-600 text-sm pt-2 ps-1'>
                      {error.clientId}
                    </p>
                  )}
                </div>
                <div className='pt-5 w-full md:w-[48%]'>
                  <label htmlFor='' className='text-sm'>
                    UPI Id (PhonePe, GPay, BHIM etc.)
                  </label>
                  <input
                    type='text'
                    value={addAccountValue.upiId}
                    name='upiId'
                    className='mt-2'
                    onChange={handleChangeDetailAccount}
                  />
                  {error.upiId && (
                    <p className='error_form_add_account text-red-600 text-sm pt-2 ps-1'>
                      {error.upiId}
                    </p>
                  )}
                </div>
              </div>
              <div className='pt-5'>
                <label htmlFor='' className='text-sm'>
                  Bank Detail for Form Point
                </label>
                <div className='flex items-center'>
                  {bankDetailFormPrint?.map((ele, ind) => {
                    return (
                      <div className='flex me-5 mt-3' key={ind}>
                        <input
                          type='radio'
                          className='accountTypeRadio me-2'
                          value={ele.value}
                          name='bankDetailFormPrint'
                          checked={
                            addAccountValue.bankDetailFormPrint === ele.value
                          }
                          key={ind}
                          onChange={e => handleChangeDetailAccount(e)}
                        />
                        <p className='text-sm'>{ele.value}</p>
                      </div>
                    )
                  })}
                </div>
                {error.bankDetailFormPrint && (
                  <p className='error_form_add_account text-red-600 text-sm pt-2 ps-1'>
                    {error.bankDetailFormPrint}
                  </p>
                )}
              </div>
              {addAccountValue.bankDetailFormPrint === 'YES' && (
                <div className='flex flex-wrap md:justify-between'>
                  <div className='pt-5 w-full md:w-[48%]'>
                    <label htmlFor='' className='text-sm'>
                      Bank Name
                    </label>
                    <input
                      type='text'
                      value={addAccountValue.bankName}
                      name='bankName'
                      className='mt-2'
                      onChange={handleChangeDetailAccount}
                    />
                    {error.bankName && (
                      <p className='error_form_add_account text-red-600 text-sm pt-2 ps-1'>
                        {error.bankName}
                      </p>
                    )}
                  </div>
                  <div className='pt-5 w-full md:w-[48%]'>
                    <label htmlFor='' className='text-sm'>
                      Bank Account
                    </label>
                    <input
                      type='text'
                      value={addAccountValue.bankAccountNumber}
                      name='bankAccountNumber'
                      className='mt-2'
                      onChange={handleChangeDetailAccount}
                    />
                    {error.bankAccountNumber && (
                      <p className='error_form_add_account text-red-600 text-sm pt-2 ps-1'>
                        {error.bankAccountNumber}
                      </p>
                    )}
                  </div>
                </div>
              )}
              <button className='auth_submit_view_more mt-5' type='submit'>
                {formLoading ? 'Submitting...' : 'Submit'}
              </button>
            </form>
          </Modal>
        </div></>}
        {formValues?.selectedAccount?.length > 0 && (
          <div className=''>
            <h3>Details</h3>
            <div className='flex flex-wrap'>
              {formValues?.selectedAccount?.map((ele, ind) => {
                return (
                  <div
                    className='ipo-card-inner my-5 p-5 apply_now_sec me-5'
                    key={ind}
                  >
                    <h3>Detail</h3>
                    <ul className='pt-4'>
                      <li className='flex pb-2 justify-between'>
                        <p className='text-gray-500 text-sm me-20'>Type</p>
                        <p className='text-sm'>{ele?.account_type}</p>
                      </li>
                      <li className='flex pb-2 justify-between'>
                        <p className='text-gray-500 text-sm me-20'>
                          PAN Number
                        </p>
                        <p className='text-sm'>{ele?.pan_number}</p>
                      </li>
                      <li className='flex pb-2 justify-between'>
                        <p className='text-gray-500 text-sm me-20'>Client ID</p>
                        <p className='text-sm'>
                          {ele?.client_id}
                        </p>
                      </li>
                      <li className='flex pb-2 justify-between'>
                        <p className='text-gray-500 text-sm me-20'>IPO Price</p>
                        <p className='text-sm'>
                          {formValues?.price}-{formValues?.price}
                        </p>
                      </li>
                      <li className='flex pb-2 justify-between'>
                        <p className='text-gray-500 text-sm me-20'>Qty</p>
                        {/* <p className='text-sm'>
                                {ipoDetailData?.bid_lot *
                                  formValues?.selectedLotSize?.value}
                              </p> */}
                              <p className='text-sm'>{ele?.ipo_qty}</p>
                      </li>
                      <li className='flex justify-between'>
                        <p className='text-gray-500 text-sm font-semibold me-20'>
                          Total Amount
                        </p>
                        <p className='text-sm font-semibold'>
                          ₹{' '}
                          {priceValue.toLocaleString() !== 'NaN' &&
                            `${priceValue.toLocaleString()}`}
                        </p>
                      </li>
                    </ul>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </>
   
    </div>
  )
}

export default ModifyAppliedIpo
