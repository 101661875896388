import {
    useDispatch,
    useSelector,
    useParams,
    React,
    useState,
    useEffect,
    getIpoDetail,
    startLoadingDetail,
    stopLoadingDetail,
    setIpoDetail,
    Images,
    commonFunctions,
    Components,
    icons,
  } from '../utils/npm'
  
  
  const { Loader, Button, ApplyButtons, Labels } = Components
  const { parseRegistrarDetail } = commonFunctions
  
  const { brokerzerodha, ipoDefault } = Images
  
  const { FaFilePdf, IoDocumentText, FaFileDownload, IoIosLink } = icons
  
  const { convertDateString } = commonFunctions
  
  function IpoSubscriptionInner () {
    const { ipoCategory } = useSelector(state => state.ipoData)
    const { ipoDetailData, ipoDetailLoading } = useSelector(
      state => state.ipoDetail
    )
    const [keyPerformanceIndicator, setKeyPerformanceIndicator] = useState([])
    const [financial_data, setFinancial_data] = useState([])
    const [registrarDetails, setRegistraDetails] = useState(
      parseRegistrarDetail(ipoDetailData?.registrar_detail)
    )
  
    const [total, setTotal] = useState({
      ipo_subscription_detail: {
        times: 0,
        offered: 0,
        applied: 0
      },
      investorCategoryTotal: {
        no_of_shares: 0,
        shares_offered: 0
      },
      application_wise_breakup: {
        totalApplied: 0
      }
    })
  
    const { id } = useParams()
    const dispatch = useDispatch()
    const getDetail = async () => {
      try {
        dispatch(startLoadingDetail())
        const response = await getIpoDetail(id)
        
        if (response && response.meta && response.meta.status_code === 200) {
          dispatch(setIpoDetail(response.data))
          const totals = response?.data?.ipo_subscription_detail?.reduce(
            (acc, item) => {
              if (
                item.category !== 'Total' &&
                item.category.trim().toLowerCase() !== 'hnis(10l +)' &&
                // item.category !== 'hnis(10l+)' &&
                item.category.trim().toLowerCase() !==  'hnis (2-10l)'
              ) {
                acc.times += item.times ?? 0
                acc.offered += item.offered ?? 0
                acc.applied += item.applied ?? 0
              }
              return acc
            },
            { times: 0, offered: 0, applied: 0 }
          )
  
          const investorCategoryTotal = response?.data?.ipo_reservation?.reduce(
            (acc, item) => {
              if (item.investor_category !== 'Total') {
                acc.no_of_shares += item.no_of_shares ?? 0
                acc.shares_offered += item.shares_offered ?? 0
              }
              return acc
            },
            {
              no_of_shares: 0,
              shares_offered: 0
            }
          )
  
          const totalApplied =
            response?.data?.appplication_wise_breakup?.data?.reduce(
              (accumulator, currentItem) => {
                const appliedValue = Number(currentItem.applied) || 0 // Ensure applied is numeric
                return accumulator + appliedValue
              },
              0
            )
  
          setTotal(prevState => ({
            ...prevState,
            ipo_subscription_detail: {
              ...prevState.ipo_subscription_detail,
              times: totals.times,
              offered: totals.offered,
              applied: totals.applied
            },
            investorCategoryTotal: {
              ...prevState.investorCategoryTotal,
              no_of_shares: investorCategoryTotal?.no_of_shares,
              shares_offered: investorCategoryTotal?.shares_offered
            },
            application_wise_breakup: {
              ...prevState.application_wise_breakup,
              totalApplied: totalApplied
            }
          }))
        } else {
          console.error('Failed to fetch IPO details.')
        }
      } catch (error) {
        console.error('Error fetching IPO details:', error)
      } finally {
        dispatch(stopLoadingDetail())
      }
    }
  
    useEffect(() => {
      getDetail()
    }, [])
  
    const checkNull = (value, defaultValue = 'NA') =>
      value === null || value === undefined ? defaultValue : value
  
    // const fieldOrder = ['category', 'applied', 'demand', 'times']
  
    const pdfDownload = (e, url) => {
      e.preventDefault()
      window.open(url, '_blank', 'noopener,noreferrer')
    }
  
    useEffect(() => {
      if (ipoDetailData?.key_performance_indicator?.[0]) {
        const reorderedData = (ipoDetailData?.key_performance_indicator).map(
          item => {
            const { kpi, ...rest } = item
            return { kpi, ...rest }
          }
        )
        setKeyPerformanceIndicator(reorderedData)
      }
  
      if (ipoDetailData?.company_financial_data?.financial_data?.[0]) {
        const reorderedData =
          (ipoDetailData?.company_financial_data?.financial_data).map(item => {
            const { period_ended, ...rest } = item
            return { period_ended, ...rest }
          })
        setFinancial_data(reorderedData)
      }
      if (ipoDetailData?.registrar_detail) {
        setRegistraDetails(parseRegistrarDetail(ipoDetailData?.registrar_detail))
      }
    }, [ipoDetailData?.key_performance_indicator])
  
    return (
      <>
        <div
          className={`container custom-width-container  ${
            ipoDetailLoading ? 'loader-cotainer' : ''
          }`}
          style={ipoDetailLoading ? { height: '38.4vh' } : {}}
        >
          {ipoDetailLoading ? (
            <Loader />
          ) : (
            <>
              {Object.keys(ipoDetailData)?.length === 0 && !ipoDetailLoading ? (
                <p className='pt-16 text-center font-semibold text-3xl'>
                  No Data Found.
                </p>
              ) : (
                <div className=''>
                  <div className='row details-page-card-grid'>
                    <div className='flex justify-end'></div>

                   
                    <div className='card detail-page-card'>
                      <div className='detail-card-inner'>
                        <div className='card-header'>
                          <div className='heading-group table_1_heading'>
                            <div className='head-img'>
                              <img
                                src={
                                  ipoDetailData?.ipo_image !== null
                                    ? ipoDetailData?.ipo_image
                                    : ipoDefault
                                }
                                onError={e => {
                                  e.target.onerror = null
                                  e.target.src = ipoDefault
                                }}
                                alt='ipo image'
                                className='ipo_detail_logo'
                              />
                            </div>
                            <div className=' heading-name lg:ps-5'>
                              <h2 className='theme-color-1 heding-details text-4xl font-medium '>
                                {ipoDetailData?.company_name}
                              </h2>
                              <div className='flex flex-wrap items-center'>
                                <p className='theme-color-4 premium mb-4'>
                                  Premium(gmp): ₹{checkNull(ipoDetailData?.gmp)}
                                </p>
                                <div
                                  className={`flex items-end ps-4`}
                                >
                                     <Labels data={ipoDetailData} classList="badge-container flex-row	right_0"/>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className='card detail-page-card'>
                    <div className='detail-card-inner'>
                      <div className='px-2'>
                        <h4 className='theme-color-1 heding-details ipo_detail_table_title_head pt-0'>
                        Standard Glass Lining IPO Shares Offered

                        </h4>
                      </div>
                      <div className='md:ps-2'>
                        <p className=' text_color'>
                          {ipoDetailData?.about_the_company}
                        </p>
                        {ipoDetailData?.about_the_company === '' && (
                          <p className=' text_color'>No Data Found.</p>
                        )}
                      </div>
                    </div>
                  </div> */}
                          <div className='max-w-40 ms-auto pb-3 pt-2 md:pt-0'>
                            <ApplyButtons data={ipoDetailData} ipoCategory={ipoCategory} classList={"broker-actions"} type="inner" />
                          </div>
                        </div>
                        <div className='overflow-x-auto'>
                          <table className='w-full text-sm text-left text-gray-500 table_1'>
                            <tbody>
                              <tr className='bg-white border-b'>
                                <td
                                  scope='row'
                                  className='px-6 py-4 font-medium  whitespace-nowrap text-md md:text-lg text-gray-700 uppercase '
                                >
                                  Price
                                </td>
                                <td className='px-6 py-4 text-md md:text-lg'>
                                  {checkNull(ipoDetailData?.price_range)}
                                </td>
                              </tr>
                              <tr className='bg-white border-b'>
                                <td
                                  scope='row'
                                  className='px-6 py-4 font-medium  whitespace-nowrap text-md md:text-lg text-gray-700 uppercase '
                                >
                                  Date
                                </td>
                                <td className='px-6 py-4 text-md md:text-lg'>
                                  {ipoDetailData?.start_date
                                    ?.split('-')
                                    ?.reverse()
                                    ?.join('-')}{' '}
                                  -{' '}
                                  {ipoDetailData?.end_date
                                    ?.split('-')
                                    ?.reverse()
                                    ?.join('-')}
                                </td>
                              </tr>
                              <tr className='bg-white border-b'>
                                <td
                                  scope='row'
                                  className='px-6 py-4 font-medium  whitespace-nowrap text-md md:text-lg text-gray-700 uppercase '
                                >
                                  Lot Size
                                </td>
                                <td className='px-6 py-4 text-md md:text-lg'>
                                  {checkNull(ipoDetailData?.bid_lot)}
                                </td>
                              </tr>
                              <tr className='bg-white border-b'>
                                <td
                                  scope='row'
                                  className='px-6 py-4 font-medium  whitespace-nowrap text-md md:text-lg text-gray-700 uppercase '
                                >
                                  Allotement
                                </td>
                                <td className='px-6 py-4 text-md md:text-lg'>
                                  {checkNull(
                                    ipoDetailData?.allotment_date
                                      ?.split('-')
                                      ?.reverse()
                                      ?.join('-')
                                  )}
                                </td>
                              </tr>
                              <tr className='bg-white border-b'>
                                <td
                                  scope='row'
                                  className='px-6 py-4 font-medium  whitespace-nowrap text-md md:text-lg text-gray-700 uppercase '
                                >
                                  Listing
                                </td>
                                <td className='px-6 py-4 text-md md:text-lg'>
                                  {checkNull(
                                    ipoDetailData?.listing_date
                                      ?.split('-')
                                      ?.reverse()
                                      ?.join('-')
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
  
                        {ipoDetailData?.ipo_lot_description?.length > 0 && (
                          <>
                            {' '}
                            <hr />
                            <p className='ipo_detail_table_title pt-4 md:pt-7 '>
                              Lot(s) Distribution
                            </p>
                            <div className='overflow-x-auto table_2 pt-3'>
                              <table className='w-full text-sm text-left text-gray-500'>
                                <thead className='text-xs text-gray-700 uppercase '>
                                  <tr>
                                    <th scope='col' className='px-6 py-3 text-md md:text-lg'>
                                      Category
                                    </th>
                                    <th scope='col' className='px-6 py-3 text-md md:text-lg'>
                                      Lots
                                    </th>
                                    <th scope='col' className='px-6 py-3 text-md md:text-lg'>
                                      Qty
                                    </th>
                                    <th scope='col' className='px-6 py-3 text-md md:text-lg'>
                                      Amount
                                    </th>
                                    <th scope='col' className='px-6 py-3 text-md md:text-lg'>
                                      Reserved
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ipoDetailData?.ipo_lot_description?.map(
                                    (row, index) => {
                                      return (
                                        <tr
                                          className='bg-white border-b'
                                          key={index}
                                        >
                                          <th
                                            scope='row'
                                            className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-lg'
                                          >
                                            {row.category}
                                          </th>
                                          <td className='px-6 py-4 text-md md:text-lg'>
                                            {row['lot(s)']}
                                          </td>
                                          <td className='px-6 py-4 text-md md:text-lg'>
                                            {row.qty}
                                          </td>
                                          <td className='px-6 py-4 text-md md:text-lg'>
                                            {row.amount}
                                          </td>
                                          <td className='px-6 py-4 text-md md:text-lg'>
                                            {row.reserved}
                                          </td>
                                        </tr>
                                      )
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>{' '}
                          </>
                        )}
  
                        {ipoDetailData?.ipo_subscription_detail?.length > 0 && (
                          <>
                            {' '}
                            <hr />
                            <p className='ipo_detail_table_title'>
                              Last updated on{' '}
                              {convertDateString(ipoDetailData?.updated_at)}
                            </p>
                            <div className='overflow-x-auto table_2 pt-3'>
                              <table className='w-full text-sm text-left text-gray-500'>
                                <thead className='text-xs text-gray-700 uppercase '>
                                  <tr>
                                    <th scope='col' className='px-6 py-3 text-md md:text-lg'>
                                      Category
                                    </th>
                                    <th scope='col' className='px-6 py-3 text-md md:text-lg'>
                                      Reserved
                                    </th>
                                    <th scope='col' className='px-6 py-3 text-md md:text-lg'>
                                      Applied
                                    </th>
                                    <th scope='col' className='px-6 py-3 text-md md:text-lg'>
                                      Times
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ipoDetailData?.ipo_subscription_detail?.map(
                                    (row, index) => {
                                      return (
                                        row.category.toLowerCase() !==
                                          'total' && (
                                          <tr
                                            className='bg-white border-b'
                                            key={index}
                                          >
                                            <td className='px-6 py-4 text-md md:text-lg'>
                                              {row.category}
                                            </td>
                                            <td className='px-6 py-4 text-md md:text-lg'>
                                              {row.offered}
                                            </td>
                                            <td className='px-6 py-4 text-md md:text-lg'>
                                              {row.applied}
                                            </td>
                                            <td className='px-6 py-4 text-md md:text-lg'>
                                              {/* {row.times} */}
                                              {+row.offered !== 0
                                                ? (
                                                    row.applied / row.offered
                                                  ).toFixed(2)
                                                : '0.00'}
                                            </td>
                                          </tr>
                                        )
                                      )
                                    }
                                  )}
                                  <tr className='bg-white border-b'>
                                    <td className='px-6 py-4 text-md md:text-lg'>Total</td>
                                    <td className='px-6 py-4 text-md md:text-lg'>
                                      {total.ipo_subscription_detail.offered}
                                    </td>
                                    <td className='px-6 py-4 text-md md:text-lg'>
                                      {total.ipo_subscription_detail.applied}
                                    </td>
                                    <td className='px-6 py-4 text-md md:text-lg'>
                                      {+total.ipo_subscription_detail.offered !==
                                      0
                                        ? (
                                            total.ipo_subscription_detail
                                              .applied /
                                            total.ipo_subscription_detail.offered
                                          ).toFixed(2)
                                        : '0.00'}
                                      {/* {total.ipo_subscription_detail.times} */}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </>
                        )}
  
                        {ipoDetailData?.appplication_wise_breakup?.data?.length >
                          0 && (
                          <>
                            {' '}
                            <hr />
                            <p className='ipo_detail_table_title'>
                              Application-Wise Breakup (Approx. no. of Apps)
                            </p>
                            <div className='overflow-x-auto table_2 pt-3'>
                              <table className='w-full text-sm text-left text-gray-500'>
                                <thead className='text-xs text-gray-700 uppercase '>
                                  <tr>
                                    <th scope='col' className='px-6 py-3 text-md md:text-lg'>
                                      {
                                        ipoDetailData?.appplication_wise_breakup
                                          ?.data[0]?.category
                                      }
                                    </th>
                                    {ipoDetailData?.appplication_wise_breakup
                                      ?.count === '4' && (
                                      <th
                                        scope='col'
                                        className='px-6 py-3 text-md md:text-lg'
                                      >
                                        {
                                          ipoDetailData?.appplication_wise_breakup
                                            ?.data[0]?.reserved
                                        }
                                      </th>
                                    )}
                                    <th scope='col' className='px-6 py-3 text-md md:text-lg'>
                                      {
                                        ipoDetailData?.appplication_wise_breakup
                                          ?.data[0]?.applied
                                      }
                                    </th>
                                    {ipoDetailData?.appplication_wise_breakup
                                      ?.count === '4' && (
                                      <th
                                        scope='col'
                                        className='px-6 py-3 text-md md:text-lg'
                                      >
                                        {
                                          ipoDetailData?.appplication_wise_breakup
                                            ?.data[0]?.times
                                        }
                                      </th>
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {ipoDetailData?.appplication_wise_breakup?.data?.map(
                                    (row, index) => {
                                      return (
                                        index > 0 && (
                                          <tr
                                            className='bg-white border-b'
                                            key={index}
                                          >
                                            <td className='px-6 py-4 text-md md:text-lg'>
                                              {row?.category}
                                            </td>
                                            {ipoDetailData
                                              ?.appplication_wise_breakup
                                              ?.count === '4' && (
                                              <td className='px-6 py-4 text-md md:text-lg'>
                                                {row?.reserved}
                                              </td>
                                            )}
                                            <td className='px-6 py-4 text-md md:text-lg'>
                                              {row?.applied}
                                            </td>
                                            {ipoDetailData
                                              ?.appplication_wise_breakup
                                              ?.count === '4' && (
                                              <td className='px-6 py-4 text-md md:text-lg'>
                                                {+row?.reserved !== 0
                                                  ? (
                                                      row?.applied / row?.reserved
                                                    ).toFixed(2)
                                                  : '0.00'}
                                              </td>
                                            )}
                                          </tr>
                                        )
                                      )
                                    }
                                  )}
                                  {total?.application_wise_breakup
                                    ?.totalApplied !== 0 && (
                                    <tr>
                                      <td
                                        colSpan={4}
                                        className='font-semibold px-6 py-4 text-md md:text-lg text-center'
                                      >
                                        Total Applications:{' '}
                                        {
                                          total?.application_wise_breakup
                                            ?.totalApplied
                                        }{' '}
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </>
                        )}
  
                        {ipoDetailData?.subscription_demand?.length > 0 && (
                          <>
                            {' '}
                            <hr />
                            <p className='ipo_detail_table_title'>
                              Subscription Demand (in ₹ crore)
                            </p>
                            <div className='overflow-x-auto table_2 pt-3'>
                              <table className='w-full text-sm text-left text-gray-500'>
                                <thead className='text-xs text-gray-700 uppercase '>
                                  <tr>
                                    <th scope='col' className='px-6 py-3 text-md md:text-lg'>
                                      {' '}
                                      Category{' '}
                                    </th>
                                    <th scope='col' className='px-6 py-3 text-md md:text-lg'>
                                      {' '}
                                      Offered{' '}
                                    </th>
                                    <th scope='col' className='px-6 py-3 text-md md:text-lg'>
                                      {' '}
                                      Demand{' '}
                                    </th>
  
                                    <th scope='col' className='px-6 py-3 text-md md:text-lg'>
                                      {' '}
                                      Times{' '}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ipoDetailData?.subscription_demand?.map(
                                    (row, index) => {
                                      return (
                                        row?.demand / row?.offered !== 0 &&
                                        row?.offered !== 0 && (
                                          <tr
                                            className='bg-white border-b'
                                            key={index}
                                          >
                                            <td className='px-6 py-4 text-md md:text-lg'>
                                              {row?.category}
                                            </td>
                                            <td className='px-6 py-4 text-md md:text-lg'>
                                              {(row?.offered).toFixed(2)}
                                            </td>
                                            <td className='px-6 py-4 text-md md:text-lg'>
                                              {(row?.demand).toFixed(2)}
                                            </td>
  
                                            <td className='px-6 py-4 text-md md:text-lg'>
                                              {row?.offered !== 0
                                                ? (
                                                    row?.demand / row?.offered
                                                  ).toFixed(2)
                                                : 'NA'}
                                            </td>
                                          </tr>
                                        )
                                      )
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </>
                        )}
                        {ipoDetailData?.interest_cost_per_share?.map(
                          (ele, index) => {
                            return (
                              <React.Fragment key={index}>
                                <p className='ipo_detail_table_title'>
                                  {ele?.title}
                                </p>
                                <div className='overflow-x-auto table_2 pt-3'>
                                  <table
                                    className='w-full text-sm text-left text-gray-500'
                                    key={index}
                                  >
                                    <thead className='text-xs text-gray-700 uppercase '>
                                      <tr>
                                        {ele?.interest_cost_per_share_data?.map(
                                          (element, ind) => {
                                            return (
                                              <th
                                                scope='col'
                                                className='px-6 py-3 text-md md:text-lg'
                                                key={ind}
                                              >
                                                {element?.percentage}%
                                              </th>
                                            )
                                          }
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr className='bg-white border-b'>
                                        {ele?.interest_cost_per_share_data?.map(
                                          (row, index) => {
                                            return (
                                              <td
                                                className='px-6 py-4 text-md md:text-lg'
                                                key={index}
                                              >
                                                {row?.value}₹
                                              </td>
                                            )
                                          }
                                        )}
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </React.Fragment>
                            )
                          }
                        )}
                      </div>
                    </div>
                    <div className='card detail-page-card'>
                      <div className='detail-card-inner'>
                        <p>About</p>
                        <div className='card-header '>
                          <h2 className='theme-color-1 heding-details ipo_detail_table_title_head'>
                            {ipoDetailData?.company_name} IPO Details
                          </h2>
                        </div>
                        <div className=''>
                          <table className='table w-full'>
                            <tbody>
                              {ipoDetailData?.issue_size !== 0 &&
                                ipoDetailData?.issue_size !== '0' && (
                                  <tr>
                                    <td className='text_color'>
                                      Total Issue Size
                                    </td>
                                    <td className='text_color'>
                                      {ipoDetailData?.issue_size} shares
                                      (aggregating up to ₹
                                      {ipoDetailData?.issue_amount} Cr)
                                    </td>
                                  </tr>
                                )}
  
                              {ipoDetailData?.fresh_issue_size !== 0 &&
                                ipoDetailData?.fresh_issue_size !== '0' &&
                                ipoDetailData?.fresh_issue_value !== 0 &&
                                ipoDetailData?.fresh_issue_value !== '0' && (
                                  <tr>
                                    <td className='text_color'>Fresh Issue</td>
                                    <td className='text_color'>
                                      {ipoDetailData?.fresh_issue_value} shares
                                      (aggregating up to ₹
                                      {ipoDetailData?.fresh_issue_size} Cr)
                                    </td>
                                  </tr>
                                )}
                              <tr>
                                <td className='text_color'>Offer for Sale</td>
                                <td className='text_color'>
                                  {ipoDetailData?.offer_of_sale !== 0 &&
                                  ipoDetailData?.offer_of_sale !== '0'
                                    ? ipoDetailData?.offer_of_sale
                                    : '-'}(aggregating up to ₹
                                      {ipoDetailData?.offer_of_sale_value} Cr)
                                </td>
                              </tr>
                              {/* )} */}
                              <tr>
                                <td className='text_color'>Issue Type</td>
                                <td className='text_color'>
                                  {ipoDetailData?.issue_type}
                                </td>
                              </tr>
                              <tr>
                                <td className='text_color'>Listing At</td>
                                <td className='text_color'>
                                  {ipoDetailData?.listing_at_group}
                                </td>
                              </tr>
                              <tr>
                                <td className='text_color'>
                                  Share holding pre issue
                                </td>
                                <td className='text_color'>
                                  {ipoDetailData?.pre_issue_share_holding}
                                </td>
                              </tr>
                              <tr>
                                <td className='text_color'>
                                  Share holding post issue 
                                </td>
                                <td className='text_color'>
                                  {ipoDetailData?.post_issue_share_holding}
                                </td>
                              </tr>
                              <tr>
                                <td className='text_color'>
                                  Market Maker Offered 
                                </td>
                                <td className='text_color'>
                                  {ipoDetailData?.market_maker_offered}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
  
                    <div className='card detail-page-card'>
                      <div className='detail-card-inner'>
                        <p>                 
                        </p>
                        <div className='card-header '>
                          <h2 className='theme-color-1 heding-details ipo_detail_table_title_head'>
                            {ipoDetailData?.company_name}  Promoter(s)
                          </h2>
                        </div>
                        <div className='pt-5' dangerouslySetInnerHTML={{__html: ipoDetailData?.company_promoter}}>
                        
                        </div>
                      </div>
                    </div>
  
                    {(ipoDetailData?.ipo_reservation?.length > 0 ||
                      keyPerformanceIndicator.length > 1 ||
                      financial_data?.length > 1) && (
                      <div className='card detail-page-card '>
                        <div className='detail-card-inner'>
                          {ipoDetailData?.ipo_reservation?.length > 0 && (
                            <>
                              <div className='px-2'>
                                <h2 className='theme-color-1 heding-details ipo_detail_table_title_head'>
                                  {ipoDetailData?.company_name} IPO Reservation
                                </h2>
                              </div>
                              <div className=''>
                                <table className='table w-full'>
                                  <thead>
                                    <tr className=''>
                                      <th scope='' className='text-left'>
                                        Investor Category
                                      </th>
                                      <th scope='' className='text-left'>
                                        Shares Offered
                                      </th>
                                      <th scope='' className='text-left'>
                                        No. of Shares
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {ipoDetailData?.ipo_reservation?.map(
                                      (ele, ind) => {
                                        return (
                                          ele?.investor_category?.toLowerCase() !==
                                            'total' && (
                                            <tr key={ind}>
                                              <th
                                                scope='row'
                                                className='text-left text_color'
                                              >
                                                {ele?.investor_category}
                                              </th>
                                              <td className='text-left text_color'>
                                                {`${ele?.shares_offered?.toFixed(
                                                  2
                                                )}%`}
                                              </td>
                                              <td className='text-left text_color'>
                                                {`${ele?.no_of_shares.toFixed(
                                                  2
                                                )}`}
                                              </td>
                                            </tr>
                                          )
                                        )
                                      }
                                    )}
                                    {ipoDetailData?.ipo_reservation?.length >
                                      0 && (
                                      <tr>
                                        <th
                                          scope='row'
                                          className='text-left text_color'
                                        >
                                          Total
                                        </th>
                                        <td className='text-left text_color'>
                                          {/* {`${ele?.shares_offered?.toFixed(2)}%`} */}
                                          {total.investorCategoryTotal.shares_offered.toFixed(
                                            2
                                          )}
                                          %
                                        </td>
                                        <td className='text-left text_color'>
                                          {/* {`${ele?.no_of_shares.toFixed(2)}`} */}
                                          {total.investorCategoryTotal.no_of_shares.toFixed(
                                            2
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </>
                          )}
  
                          {keyPerformanceIndicator.length > 1 && (
                            <>
                              <h3
                                className={`theme-color-1 heding-details ${
                                  ipoDetailData?.ipo_reservation?.length > 0
                                    ? 'pt-7'
                                    : ''
                                } ipo_detail_table_title_head`}
                              >
                                {' '}
                                Key Performance Indicators
                              </h3>
                              <div className='overflow-x-auto'>
                                <table className='table w-full'>
                                  <thead>
                                    <tr></tr>
                                  </thead>
                                  <tbody>
                                    {keyPerformanceIndicator.length > 0 &&
                                      Object.keys(
                                        keyPerformanceIndicator[0]
                                      )?.map((ele, ind) => {
                                        return (
                                          <tr key={ind}>
                                            <td className='font-semibold'>
                                              {/* { keyPerformanceIndicator[0][ele] } */}
                                              {keyPerformanceIndicator[0][ele] !==
                                                '0' &&
                                              keyPerformanceIndicator[0][ele] !==
                                                0
                                                ? keyPerformanceIndicator[0][ele]
                                                : '-'}
                                            </td>
                                            {keyPerformanceIndicator?.map(
                                              (elem, index) => {
                                                return (
                                                  index > 0 && (
                                                    <td
                                                      key={index}
                                                      className={`${
                                                        ind === 0
                                                          ? 'font-semibold'
                                                          : ' text_color'
                                                      }`}
                                                    >
                                                      {elem[ele] !== '0' &&
                                                      elem[ele] !== 0
                                                        ? elem[ele]
                                                        : '-'}
                                                    </td>
                                                  )
                                                )
                                              }
                                            )}
                                          </tr>
                                        )
                                      })}
                                  </tbody>
                                </table>
                              </div>
                              <hr />
                            </>
                          )}
  
                          {financial_data?.length > 1 && (
                            <>
                              {' '}
                              <h3 className='theme-color-1 heding-details pt-7 ipo_detail_table_title_head'>
                                {' '}
                                Company Financials
                              </h3>
                              <div className='overflow-x-auto'>
                                <table className='table w-full'>
                                  <thead>
                                    <tr></tr>
                                  </thead>
                                  <tbody>
                                    {financial_data.length > 0 &&
                                      Object.keys(financial_data[0])?.map(
                                        (ele, ind) => {
                                          return (
                                            <tr key={ind}>
                                              <td className='font-semibold'>
                                                {/* { keyPerformanceIndicator[0][ele] } */}
                                                {financial_data[0][ele] !== '0' &&
                                                financial_data[0][ele] !== 0
                                                  ? financial_data[0][ele]
                                                  : '-'}
                                              </td>
                                              {financial_data?.map(
                                                (elem, index) => {
                                                  return (
                                                    index > 0 && (
                                                      <td
                                                        key={index}
                                                        className={`${
                                                          ind === 0
                                                            ? 'font-semibold'
                                                            : ' text_color'
                                                        }`}
                                                      >
                                                        {elem[ele] !== '0' &&
                                                        elem[ele] !== 0
                                                          ? elem[ele]
                                                          : '-'}
                                                      </td>
                                                    )
                                                  )
                                                }
                                              )}
                                            </tr>
                                          )
                                        }
                                      )}
                                  </tbody>
                                </table>
                              </div>
                              <h6 className='text-end font-semibold pt-4 text-md md:text-lg'>
                                {
                                  ipoDetailData?.company_financial_data
                                    ?.financial_amount_type
                                }
                              </h6>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    <div className='card detail-page-card'>
                      <div className='detail-card-inner'>
                        <div className='px-2'>
                          <h4 className='theme-color-1 heding-details ipo_detail_table_title_head pt-0'>
                            About Company
                          </h4>
                        </div>
                        <div className='md:ps-2'>
                          <p className=' text_color'>
                            {ipoDetailData?.about_the_company}
                          </p>
                          {ipoDetailData?.about_the_company === '' && (
                            <p className=' text_color'>No Data Found.</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='card detail-page-card'>
                      <div className='detail-card-inner'>
                        <div className='card-header'>
                          <h4 className='theme-color-1 heding-details ipo_detail_table_title_head '>
                            Lead Manager(s)
                          </h4>
                        </div>
                        <ul>
                          <li className=' text_color'>
                            {ipoDetailData?.lead_managers}
                          </li>
                          {(ipoDetailData?.lead_managers === '' ||
                            ipoDetailData?.lead_managers === null) && (
                            <p className=' text_color'>No Data Found.</p>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className='card detail-page-card'>
                      <div className='detail-card-inner'>
                        <div className='card-header'>
                          <h4 className='theme-color-1 heding-details ipo_detail_table_title_head '>
                            Strength Factor
                          </h4>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: ipoDetailData?.company_strenght
                          }}
                          className='p-2  text_color riskFector'
                        ></div>
                        {ipoDetailData?.company_strenght === '' && (
                          <p>No Data Found.</p>
                        )}
                      </div>
                    </div>
                    <div className='card detail-page-card'>
                      <div className='detail-card-inner'>
                        <div className='card-header'>
                          <h4 className='theme-color-1 heding-details ipo_detail_table_title_head '>
                            Company Address
                          </h4>
                        </div>
                        {Object.keys(ipoDetailData?.company_address)?.length ===
                          0 && <p>No Data Found.</p>}
                        <ul>
                          {ipoDetailData?.company_address?.Email && (
                            <li>
                              <strong>Email:</strong>{' '}
                              <span className='text_color text-sm'>
                                {ipoDetailData?.company_address?.Email}
                              </span>
                            </li>
                          )}
                          {ipoDetailData?.company_address?.Phone && (
                            <li>
                              <strong>Phone:</strong>{' '}
                              <span className='text_color text-sm'>
                                {ipoDetailData?.company_address?.Phone}
                              </span>
                            </li>
                          )}
                          {ipoDetailData?.company_address?.Website && (
                            <li>
                              <strong>Website:</strong>{' '}
                              <span className='text_color text-sm'>
                                {ipoDetailData?.company_address?.Website}
                              </span>
                            </li>
                          )}
                          {ipoDetailData?.company_address?.address && (
                            <li>
                              <strong>address:</strong>{' '}
                              <span className='text_color text-sm'>
                                {ipoDetailData?.company_address?.address}
                              </span>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className='card detail-page-card'>
                      <div className='detail-card-inner'>
                        <div className='card-header'>
                          <h4 className='theme-color-1 heding-details ipo_detail_table_title_head '>
                            Risk Factor
                          </h4>
                        </div>
                        {ipoDetailData?.company_weakness === '' && (
                          <p>No Data Found.</p>
                        )}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: ipoDetailData?.company_weakness
                          }}
                          className='p-2 riskFector'
                        ></div>
                      </div>
                    </div>
                    <div className='card detail-page-card'>
                      <div className='detail-card-inner'>
                        <div className='card-header'>
                          <h4 className='theme-color-1 heding-details ipo_detail_table_title_head '>
                            Registrar
                          </h4>
                        </div>
                        <ul>
                          {ipoDetailData?.registrar === '' &&
                            ipoDetailData?.registrar_detail === '' &&
                            ipoDetailData?.registrar_detail ==
                              '0'(<li>No Data Found.</li>)}
                          {ipoDetailData?.registrar !== '' &&
                            ipoDetailData?.registrar != 0 && (
                              <li className='flex items-center'>
                                <span className='text-sm text_color'>
                                  {ipoDetailData?.registrar}
                                </span>
                              </li>
                            )}
                          {registrarDetails?.phone &&
                            registrarDetails?.phone != 0 && (
                              <li className='flex items-center'>
                                <span className='font-semibold text-sm pe-2'>
                                  Phone:{' '}
                                </span>
                                <span className='text-sm text_color'>
                                  {registrarDetails?.phone}
                                </span>
                              </li>
                            )}
                          {registrarDetails?.email &&
                            registrarDetails?.email != 0 && (
                              <li className='flex items-center'>
                                <span className='font-semibold text-sm pe-2'>
                                  Email:{' '}
                                </span>
                                <span className='text-sm text_color'>
                                  {registrarDetails?.email}
                                </span>
                              </li>
                            )}
                          {registrarDetails?.phone &&
                            registrarDetails?.phone != 0 && (
                              <li className='flex items-center'>
                                <span className='font-semibold text-sm pe-2'>
                                  Name:{' '}
                                </span>
                                <span className='text-sm text_color'>
                                  {registrarDetails?.name}
                                </span>
                              </li>
                            )}
                        </ul>
                      </div>
                    </div>
                    <div className='flex flex-wrap'>
                      {
                        // (ele?.link !== undefined && ele?.link !== "") &&
                        ipoDetailData?.ipo_doc_link?.map((ele, ind) => {
                          return (
                            ele?.link !== '' && (
                              <button
                                onClick={e => pdfDownload(e, ele?.link)}
                                className='auth_submit_view_more flex items-center me-4 uppercase mb-4'
                                key={ind}
                              >
                                <FaFileDownload className='text-2xl me-2' />
                                <span className='text-sm'>{ele?.lable}</span>
                              </button>
                            )
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </>
    )
  }
  
  export default IpoSubscriptionInner
  