import { createSlice } from '../../utils/npm'

const modifyIpoDataSlice = createSlice({
  name: 'ModifyIpoData',
  initialState: {
    data: {},
  },
  reducers: {
    setModifiyAppliedIpoData: (state, action) => {
      state.data = action.payload
    },
  }
})

export const {
    setModifiyAppliedIpoData,
} = modifyIpoDataSlice.actions

export default modifyIpoDataSlice.reducer
