const AppPrivacyPolicyPage = () => {
  return (
    <>
      {/* <section class=" bg-gradient-to-b from-gray-100 to-white py-10 px-5">
  <div class="container mx-auto px-4 py-10 shadow-md rounded-lg" style={{boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    <h2 class="font-bold text-2xl lg:text-4xl text-center text-gray-800">
      Privacy Policy updated
    </h2>
    <p class="text-sm md:text-base text-gray-600 pb-5 pt-6 text-center ">
      This Privacy Policy explains how IPO Trend ("we," "our," or "us")
      collects, uses, and protects your information when you use our website at 
      <a
        href="https://ipo-trend.com/"
        target="_blank"
        rel="noopener noreferrer"
        class="text-blue-600 hover:text-blue-800 underline font-medium px-1"
      >
        ipo-trend.com
      </a>
     . By accessing and using the Website, you agree to the
      practices outlined in this Privacy Policy.
    </p>

    <div class="py-8">
      <h3 class="text-2xl font-semibold pb-6 text-gray-700 border-b-2 border-gray-300">
        Table of Contents
      </h3>
      <div class="space-y-6 pt-8">
        <div>
          <h4 class="text-lg font-medium text-gray-800">
            1. Information We Collect
          </h4>
          <p class="pt-2 text-sm text-gray-600">
            We collect information to provide and improve our services. This
            includes personal information such as your name and email, as well
            as non-personal data like browser type and pages visited.
          </p>
        </div>
        <div>
          <h4 class="text-lg font-medium text-gray-800">
            2. How We Use Your Information
          </h4>
          <p class="pt-2 text-sm text-gray-600">
            We use your information to operate our services, personalize your
            experience, improve performance, and ensure security.
          </p>
        </div>
        <div>
          <h4 class="text-lg font-medium text-gray-800">
            3. Sharing Your Information
          </h4>
          <p class="pt-2 text-sm text-gray-600">
            We do not sell your information. However, we may share it with
            trusted partners or when required by law.
          </p>
        </div>
        <div>
          <h4 class="text-lg font-medium text-gray-800">
            4. Cookies and Tracking
          </h4>
          <p class="pt-2 text-sm text-gray-600">
            We use cookies to improve your experience and gather analytics. You
            can manage cookie preferences in your browser settings.
          </p>
        </div>
        <div>
          <h4 class="text-lg font-medium text-gray-800">
            5. Data Security
          </h4>
          <p class="pt-2 text-sm text-gray-600">
            While we implement security measures, no system is fully secure.
            Use the Website acknowledging this risk.
          </p>
        </div>
        <div>
          <h4 class="text-lg font-medium text-gray-800">
            6. Your Rights
          </h4>
          <p class="pt-2 text-sm text-gray-600">
            You may have rights such as accessing, correcting, or deleting your
            personal data, depending on your location.
          </p>
        </div>
        <div>
          <h4 class="text-lg font-medium text-gray-800">
            7. Changes to This Policy
          </h4>
          <p class="pt-2 text-sm text-gray-600">
            We may update this policy periodically. Changes will be posted on
            this page with the effective date.
          </p>
        </div>
        <div>
          <h4 class="text-lg font-medium text-gray-800">
            8. Contact Us
          </h4>
          <p class="pt-2 text-sm text-gray-600">
            For questions or concerns, please contact us at
            <a
              href="mailto:assilaair@assila.com"
              class="text-blue-600 hover:text-blue-800 underline font-medium"
            >
              assilaair@assila.com
            </a>
            .
          </p>
        </div>
      </div>
    </div>

    <p class="text-sm text-center text-gray-500 ">
      This document was last updated on
      <span class="font-semibold text-gray-700">June 4, 2024</span>.
    </p>
  </div>
</section> */}

      <section class=" bg-gradient-to-b from-gray-100 to-white py-10 px-5">
        <div
          class="container mx-auto px-4 py-10 shadow-md rounded-lg"
          style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
        >
          <h2 class="font-bold text-2xl lg:text-4xl text-center text-gray-800">
            Privacy Policy for IPO-Trend
          </h2>
          <p className="text-sm md:text-base text-gray-600 pt-10">
            <span className="font-bold">Effective Date:</span> 2025-01-21{" "}
          </p>
          <p class="text-sm md:text-base text-gray-600 pb-5 pt-2 ">
            Welcome to IPO-Trend, developed by Tracewave Transparency Pvt. Ltd..
            Protecting your privacy is of utmost importance to us. This privacy
            policy outlines how we collect, use, and protect your information
            when you use our app.
          </p>

          <h3 >1.  Information Collection and Use: </h3>
          <p className="text-sm md:text-base text-gray-600 pb-5 pt-2 ">
            IPO-Trend provides users with access to IPO-related information,
            news, and settings to manage their profiles. To offer these
            services, we collect certain types of information as outlined below.
          </p>

          <h4 >1.1. Types of Data Collected </h4>

          <h3 className="pt-3">OTP Verification Data</h3>
          <p className="text-sm md:text-base text-gray-600 pb-5 pt-2 ">
            {" "}
            We collect your phone number to send a one-time password (OTP) for
            secure login and account creation.
          </p>

          <h3 className="pt-3">Personal Data </h3>
          <p className="text-sm md:text-base text-gray-600 pb-5 pt-2 ">
            {" "}
            While using our app, you have the option to provide the following
            personal information to maintain your profile. Providing this
            information is entirely optional and not required to use the app
          </p>
          <ul className="list-disc list-inside">
            <li className="text-sm"> First Name and Last Name </li>
            <li className="text-sm">Email (optional)</li>
            <li className="text-sm">Profile Picture (optional)</li>
          </ul>
          <p className="text-sm md:text-base text-gray-600 pb-5 pt-2 ">
            {" "}
            You can continue to use all app features even if you don’t provide this data. This data is
            used to personalize your experience.
          </p>

          <h4>1.2.  How We Use Your Information</h4>
          <p className="text-sm md:text-base text-gray-600 pb-5 pt-2">
            We use your data for the following purposes:
          </p>

          <ul className="list-disc list-inside space-y-2">
            <h4 >
              To Provide and Improve Our Services
            </h4>
            <ul className="list-disc list-inside">
              <li className="text-sm">
                Enable secure login via OTP verification
              </li>
              <li className="text-sm">
                Display IPO lists, news, and other related content.
              </li>
              <li className="text-sm">
                Allow you to update your profile and manage account settings.
              </li>
            </ul>

            <h3 className="pt-3">To Communicate with You</h3>
            <ul className="list-disc list-inside">
              <li className="text-sm">
                Notify you about updates, new features, or important information
                related to IPOs and our services.
              </li>
            </ul>

            <h3 className="pt-3">To Enhance User Experience</h3>
            <ul className="list-disc list-inside">
              <li className="text-sm">
                Personalize content based on your preferences and interactions.
              </li>
              <li className="text-sm">
                Provide seamless navigation between app sections, including IPO
                details, news, and settings.
              </li>
            </ul>

            <h3 className="pt-3">To Comply with Legal Obligations</h3>
            <ul className="list-disc list-inside">
              <li className="text-sm">
                Retain and disclose information as required by law or legal
                processes.
              </li>
            </ul>
          </ul>
          <div>
            <h3 className=" pt-3">2. Third-Party Services</h3>
            <p className="text-sm md:text-base text-gray-600 pb-3 pt-2">
              We may share data with trusted third-party services to enhance
              functionality and analytics. These include:
            </p>

            <ul className="list-disc list-inside">
              <li className="text-sm">
                Google Analytics for Firebase: To monitor app usage and
                performance
              </li>
              <li className="text-sm">
                Firebase Crashlytics: For error reporting and app stability
                improvements
              </li>
              <li className="text-sm">
                Microsoft Clarity: For user behavior analytics.
              </li>
            </ul>
            <p className="text-sm md:text-base text-gray-600 pb-5 pt-2">
              All third-party providers adhere to strict data protection
              standards.
            </p>

            <h3 className="pt-3">3. Data Securit</h3>
            <p className="text-sm md:text-base text-gray-600 pb-5 pt-2">
              Our app is not intended for individuals under the age of 18. We do
              not knowingly collect personal information from children. If you
              believe a child under 18 has provided personal information, please
              contact us, and we will take appropriate action to delete the
              information.
            </p>

            <h3 className=" pt-2">4. Children’s Privacy</h3>
            <p className="text-sm md:text-base text-gray-600 pb-5 pt-2">
              The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage, is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security.
            </p>

            <h3 className=" pt-2">5. Data Retention</h3>
            <p className="text-sm md:text-base text-gray-600 pb-5 pt-2">
              We retain your data for as long as necessary to provide our
              services or comply with legal obligations. If you wish to delete
              your account or personal information, contact us using the details
              below.
            </p>

            <h3 className=" pt-2">6. Contact Us</h3>
            <p className="text-sm md:text-base text-gray-600 pb-5 pt-2">
              For questions or concerns about this privacy policy, please
              contact us:
            </p>
            <ul className="list-disc list-inside">
              <li className="text-sm">
                Email: <a href="#"  class="text-blue-600 hover:text-blue-800 underline font-medium">info@ipotrend.com</a> ,<a href="#"  class="text-blue-600 hover:text-blue-800 underline font-medium"> info@tracewavetransparency.com</a>
              </li>
              <li className="text-sm">
                Business Hours: Monday to Friday, 09:30 AM to 6:00 PM (excluding
                public holidays).
              </li>
            </ul>

            {/*            
            <h3 className=" pt-2">Contact Us</h3>
            <p className="text-sm md:text-base text-gray-600 pb-5 pt-2">
              If you have any questions or concerns, please contact us at{" "}
              <a
                href="mailto:info@ipotrend.com"
                className="text-blue-600 underline"
              >
                info@ipotrend.com
              </a>{" "}
              or during business hours [09:30 AM to 6:00 PM from Monday to
              Friday, excluding Public Holidays].
            </p> */}
            <p className="text-sm md:text-base text-gray-600 pt-5">
              Thank you for trusting the IPO Plus!
              {/* 2024-12-01. */}
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default AppPrivacyPolicyPage;