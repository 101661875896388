import { createBrowserRouter, Navigate } from 'react-router-dom'
import { PublicRoute, ProtectedRoute } from './PrivatePublicRoute'
import {
  ContactUs,
  ForgotPassword,
  HomePage,
  Login,
  SignUp,
  VerifyCode,
  SetPassWord,
  Profilepage,
  BrokerPage,
  NewsPage,
  NewsDetail,
  AboutUs,
  Components,
  CurrentIpoPage,
  UpcomingIpoPage,
  ListedIpoPage,
  BrokerDetail,
  TermsAndConditionsPage,
  PrivacyPolicyPage,
  FAQsPage,
  ApplyPage,
  AppliedIpoList,
  commonFunctions,
  DeleteAccount,
  PrivacyPolicyIpoTrend,
  IPODashboardPage,
  IPODetailsPage
} from '../utils/npm'
import {PATHS} from "../utils/utils"
import ModifyAppliedIpo from '../pages/ModifyAppliedIpo'
import IpoSubscriptionInner from '../components/ipoSubscriptionInner'
import AppPrivacyPolicyPage from '../pages/AppPrivacyPolicyPage'
// import IPODashboardPage from '../pages/IPODashboardTable'

const {  IpoDetail, Header, Footer } = Components

const Layout = ({ children }) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
)

const wrapRoute = (route, wrapper) => {
  if (wrapper === 'public') {
    return {
      ...route,
      element: <PublicRoute>{route.element}</PublicRoute>,
    };
  } else if (wrapper === 'protected') {
    return {
      ...route,
      element: <ProtectedRoute>{route.element}</ProtectedRoute>,
    };
  }
  return {
    ...route,
    element: <Layout>{route.element}</Layout>,
  };
};

// Define Routes

// const routes = [
//   // Public Routes
//   { path: '/login', element: <Login />, wrapper: 'public' },
//   { path: '/signup', element: <SignUp />, wrapper: 'public' },

//   // Protected Routes
//   { path: '/profile', element: <Profilepage />, wrapper: 'protected' },
//   { path: '/appliedIpo', element: <AppliedIpoList />, wrapper: 'protected' },
//   { path: '/apply/:id', element: <ApplyPage />, wrapper: 'protected' },
//   { path: '/modify/:id', element: <ModifyAppliedIpo />, wrapper: 'protected' },

//   // General Routes (No Wrapper)
//   { path: '/', element: <HomePage /> },
//   { path: '/ipo/:id', element: <IpoDetail />},
//   { path: '/contactUs', element: <ContactUs /> },
//   { path: '/forgotPassword', element: <ForgotPassword /> },
//   { path: '/verifyCode', element: <VerifyCode /> },
//   { path: '/setNewPassword', element: <SetPassWord /> },
//   { path: '/broker', element: <BrokerPage /> },
//   { path: '/news', element: <NewsPage /> },
//   { path: '/about-us', element: <AboutUs /> },
//   { path: '/live', element: <CurrentIpoPage /> },
//   { path: '/upcoming', element: <UpcomingIpoPage /> },
//   { path: '/listed', element: <ListedIpoPage /> },
//   { path: '/termsAndConditions', element: <TermsAndConditionsPage /> },
//   { path: '/privacyPolicy', element: <PrivacyPolicyPage /> },
//   { path: '/marketNews/:id', element: <NewsDetail /> },
//   { path: '/broker/:id', element: <BrokerDetail /> },
//   { path: '/faqs', element: <FAQsPage /> },
//   // Catch-All Route
//   { path: '/*', element: <Navigate to="/" /> },
// ];

const routes = [
  // Public Routes
  { path: PATHS.LOGIN, element: <Login />, wrapper: 'public' },
  { path: PATHS.SIGNUP, element: <SignUp />, wrapper: 'public' },

  // Protected Routes
  { path: PATHS.PROFILE, element: <Profilepage />, wrapper: 'protected' },
  { path: PATHS.APPLIED_IPO, element: <AppliedIpoList />, wrapper: 'protected' },
  { path: PATHS.APPLY, element: <ApplyPage />, wrapper: 'protected' },
  { path: PATHS.MODIFY, element: <ModifyAppliedIpo />, wrapper: 'protected' },

  // General Routes (No Wrapper)
  { path: PATHS.HOME, element: <HomePage /> },
  { path: PATHS.IPODASHBOARD, element: <IPODashboardPage /> },
  { path: PATHS.IPODETAILS, element: <IPODetailsPage /> },


  // { path: PATHS.IPO_DETAIL, element: <IpoDetail /> },
  { path: PATHS.IPO_DETAIL, element: <IpoSubscriptionInner /> },
  { path: PATHS.DELETEACCOUNT, element: <DeleteAccount />, },
  { path: PATHS.CONTACT_US, element: <ContactUs /> },
  { path: PATHS.FORGOT_PASSWORD, element: <ForgotPassword /> },
  { path: PATHS.VERIFY_CODE, element: <VerifyCode /> },
  { path: PATHS.SET_NEW_PASSWORD, element: <SetPassWord /> },
  { path: PATHS.SET_NEW_PASSWORD, element: <SetPassWord /> },
  { path: PATHS.BROKER, element: <BrokerPage /> },
  { path: PATHS.NEWS, element: <NewsPage /> },
  { path: PATHS.ABOUT_US, element: <AboutUs /> },
  { path: PATHS.LIVE, element: <CurrentIpoPage /> },
  { path: PATHS.UPCOMING, element: <UpcomingIpoPage /> },
  { path: PATHS.LISTED, element: <ListedIpoPage /> },
  { path: PATHS.TERMS_AND_CONDITIONS, element: <TermsAndConditionsPage /> },
  { path: PATHS.PRIVACY_POLICY, element: <PrivacyPolicyPage /> },
  { path: PATHS.APP_PRIVACY_POLICY, element: <AppPrivacyPolicyPage /> },

  { path: PATHS.PRIVACY2, element: <PrivacyPolicyIpoTrend /> },
  { path: PATHS.MARKET_NEWS_DETAIL, element: <NewsDetail /> },
  { path: PATHS.BROKER_DETAIL, element: <BrokerDetail /> },
  { path: PATHS.FAQS, element: <FAQsPage /> },
  { path: PATHS.IPOSUBINNER, element: <IpoSubscriptionInner /> },

  // Catch-All Route
  { path: PATHS.NOT_FOUND, element: <Navigate to={PATHS.HOME} /> },
];

// Wrap Routes Dynamically
const wrappedRoutes = routes.map((route) =>
  wrapRoute(route, route.wrapper)
);

// Create Router
export const routerLinks = createBrowserRouter(wrappedRoutes, {
  future: {
    v7_fetcherPersist: true,
    v7_startTransition: true,
    v7_relativeSplatPath: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true,
  },
});