import { routerLinks } from './routes/AppRoutes'
import {
  Components,
  RouterProvider,
  Suspense,
  store,
  Provider,
  ToastContainer,
  useLocation,
  useEffect,
  AOS
} from './utils/npm'
import { useLayoutEffect } from 'react'
import { ScrollToTop } from './utils/utils'

const { Loader } = Components

// Scroll to Top Function


function App () {
  // Hook to listen for location changes

  useLayoutEffect(() => {
    ScrollToTop() // Scroll to top on initial render
    // const disableRightClick = (event) => {
    //   event.preventDefault();
    // };
    // const disableDevTools = (event) => {
    //   if (
    //     event.key === "F12" || // F12
    //     (event.ctrlKey && event.shiftKey && event.key === "I") || 
    //     (event.ctrlKey && event.key === "U")
    //   ) {
    //     event.preventDefault();
    //   }
    // };
    // document.addEventListener('contextmenu', disableRightClick);
    // document.addEventListener('keydown', disableDevTools);
    // return () => {
    //   document.removeEventListener('contextmenu', disableRightClick);
    //   document.removeEventListener('keydown', disableDevTools);
    // };
  }, [])


  return (
    <Provider store={store}>
      <Suspense
        fallback={
          <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
            <Loader />
          </div>
        }
      >
        <ToastContainer
          position='top-right'
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
          limit={1} 
        />
           {/* <BrowserRouter basename="/TTprofrontend/"> */}
        <RouterProvider
          router={routerLinks}
          future={{
            v7_startTransition: true
          }}
        />
        {/* </BrowserRouter> */}
      </Suspense>
    </Provider>
  )
}

export default App
