import { generateTokenApi } from '../api/authApi'
import {
  axiosInstance,
  CryptoJS,
  commonFunctions,
  useNavigate,
  removeLoginData,
  removeProfileData
} from '../utils/npm'

export const ScrollToTop = () => {
  // window.scrollTo(0, 0)
}

export const convertDateString = isoDate => {
  const date = new Date(isoDate)
  const day = date.getDate().toString().padStart(2, '0')
  const month = date.toLocaleString('en-US', { month: 'short' }) // Short month name
  const year = date.getFullYear()
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  const seconds = date.getSeconds().toString().padStart(2, '0')
  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`
}

export const extractLink = data => {
  const linkRegex = /https?:\/\/[^\s,]+/
  const match = data?.match(linkRegex)
  return match ? match[0] : ''
}

export const removeLastNumberFromUrl = url => {
  const urlObj = new URL(url)
  const pageSize = urlObj.searchParams.get('page_size')
  const pageNo = urlObj.searchParams.get('page')
  urlObj.searchParams.set('page', '')

  return {
    updatedUrl: urlObj.toString(),
    pageSize,
    pageNo
  }
}

export const setPageParameter = (url, pageNumber) => {
  const urlObj = new URL(url)
  urlObj.searchParams.set('page', pageNumber)
  return urlObj.toString()
}
export const brokersList = [
  {
    id: 'zerodha',
    title: 'Zerodha',
    Rating: '4.5',
    img: 'brokerzerodha',
    titleText: 'Discount Broker',
    aboutBroker:
      'Zerodha, an Indian discount stockbroker, provides online trading and investment services. Founded in 2010 by Nithin Kamath and Nikhil Kamath, Zerodha has established a substantial presence in the market. As of February 2024, the platform boasts an active client base of 7,223,525. ',
    productBasket:
      'The segments in which you can invest (includes Equity, Commodity, Currency, Futures and Options) through this broker',
    productBasketList: [
      {
        id: 1,
        name: 'Equity',
        background: '#abd1d257',
        dark: '#375c5c'
      },
      {
        id: 2,
        name: 'Commodity',
        background: '#e4deed',
        dark: '#8362b5'
      },
      {
        id: 3,
        name: 'Currency',
        background: '#edecde',
        dark: '#9d9524'
      },
      {
        id: 4,
        name: 'Futures',
        background: '#b0d0b7',
        dark: '#637868'
      },
      {
        id: 5,
        name: 'Options',
        background: '#b0b7d0',
        dark: '#5e6fa8'
      }
    ],
    brokaragePlan:
      '1 or more plans offered by this broker, which outlines, Account opening and maintenance fees charged by this broker, Brokerage charges and Margins provided. Use plan switcher to see the difference in offerings, if multiple plans are available.',
    singlePlan: 'Single Plan',
    charges: {
      ['account opening']: '₹300',
      ['account maintenance']: '₹300',
      ['call trade']: '₹50'
    },
    Brokerage: {
      ['equity delivery']: {
        value1: 'Zero',
        value2: ''
      },
      ['equity intraday']: {
        value1: '0.03%',
        value2: 'up to Rs 20'
      },
      ['equity futures']: {
        value1: '0.03%',
        value2: 'up to Rs 20'
      },
      ['equity options']: {
        value1: '₹20',
        value2: 'per order'
      },
      ['currency futures']: {
        value1: '0.03%',
        value2: ''
      },
      ['currency options']: {
        value1: '₹20',
        value2: ''
      },
      ['commodity futures']: {
        value1: '0.03%',
        value2: ''
      },
      ['commodity options']: {
        value1: '₹20',
        value2: ''
      }
    },
    Margins: {
      ['Equity Delivery']: {
        value1: '0x',
        value2: 'As per exchange'
      },
      ['Equity Intraday']: {
        value1: '3x',
        value2: 'As per exchange'
      },
      ['Equity Futures']: {
        value1: '2.5x',
        value2: 'As per exchange'
      },
      ['Equity Options']: {
        value1: '2.5x',
        value2: 'As per exchange'
      },
      ['Currency Futures']: {
        value1: '2.5x',
        value2: 'As per exchange'
      },
      ['Currency Options']: {
        value1: '2.5x',
        value2: 'As per exchange'
      },
      ['Commodity Futures']: {
        value1: '2.5x',
        value2: 'As per exchange'
      },
      ['Commodity Options']: {
        value1: '0x',
        value2: 'As per exchange'
      }
    },
    PlatformsOffered:
      'Set of software & applications offered by the broker for executing buy and sell orders online. These are names of their portals and mobile apps, for full desc- Full Platform Details.',
    PlatformsOfferedArray: [
      'Zerodha Kite web',
      'Zerodha Kite Mobile Application',
      'Zerodha Coin',
      'Zerodha Console',
      'Sentinel',
      'Zerodha Varsity'
    ],
    OtherDetailsProsLine: 'Advantages and Strengths of this broker.',
    OtherDetailsProsList: [
      'Zerodha imposes a minimal trading fee and does not levy any charges for equity delivery.',
      'A fee calculator is available on the Zerodha website, enabling users to anticipate trading costs.',
      'There is no requirement to maintain a minimum balance when opening an account with Zerodha.',
      'The processes for deposits and withdrawals are user-friendly and free of charge.',
      'No platform deposit fee is required. Users can conveniently transfer funds to their accounts via UPI.'
    ],
    OtherDetailsconsLine:
      'Disadvantages of this broker that it needs to improve upon.',
    OtherDetailsconsList: [
      'NRIs, HUFs, and companies can open a demat account only through an offline process.',
      'Responses from customer support may be delayed, and software glitches can occur at times.',
      'Each investment instrument has a dedicated platform, such as Kite for shares, Coin for mutual funds, and Golden Pi for bonds.',
      'The offline procedure is cumbersome and time-consuming, even for individual accounts, due to the limited number of physical branches.'
    ],
    AdditionalFeatures:
      'Extra features provided by the broker at little or no extra cost. Like research reports, SMS alerts etc.',
    AdditionalFeaturesList: [
      {
        id: 1,
        name: '3 in 1 Account',
        icon: false
      },
      {
        id: 2,
        name: 'free trading calls',
        icon: false
      },
      {
        id: 3,
        name: 'free research',
        icon: false
      },
      {
        id: 4,
        name: 'sms alerts',
        icon: true
      },
      {
        id: 5,
        name: 'margin funding',
        icon: false
      },
      {
        id: 6,
        name: 'margin against share',
        icon: true
      }
    ],
    OtherInvestmentOptions:
      'Services provided by the broker for investing in other financial assets such as Mutual funds, Insurance, Bonds etc.',
    OtherInvestmentOptionsList: [
      {
        id: 1,
        name: 'mutual funds',
        icon: true
      },
      {
        id: 2,
        name: 'ipo platform',
        icon: true
      },
      {
        id: 3,
        name: 'bonds debt',
        icon: true
      },
      {
        id: 4,
        name: 'exchange traded funds',
        icon: true
      }
    ],
    equityChargesList: [
      {
        name: 'delivery',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'BSE:',
                value: '0.00375%'
              },
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.1%'
              }
            ]
          },
          {
            id: 4,
            title: 'sebi charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      },
      {
        name: 'intraday',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'BSE:',
                value: '0.00375%'
              },
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing Charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp Charge',
            values: [
              {
                heading: '',
                value: '₹ 0'
              }
            ]
          },
          {
            id: 4,
            title: 'Gst',
            values: [
              {
                heading: '',
                value: '18% on Brokerage + Transaction'
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.025%'
              }
            ]
          },
          {
            id: 4,
            title: 'sebi charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      },
      {
        name: 'future',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'NSE: ',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing Charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp Charge',
            values: [
              {
                heading: '',
                value: '₹ 0'
              }
            ]
          },
          {
            id: 4,
            title: 'Gst',
            values: [
              {
                heading: '',
                value: '18% on Brokerage + Transaction'
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.0125%'
              }
            ]
          },
          {
            id: 4,
            title: 'sebi charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      },
      {
        name: 'options',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing Charges',
            values: [
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp Charge',
            values: [
              {
                heading: '',
                value: '₹ 0'
              }
            ]
          },
          {
            id: 4,
            title: 'Gst',
            values: [
              {
                heading: '',
                value: '18% on Brokerage + Transaction'
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.125% buy 0.0625% sell'
              }
            ]
          },
          {
            id: 4,
            title: 'Sebi Charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      }
    ],
    openDeatAccountUrl: 'https://zerodha.com/open-account'
  },
  {
    id: 'angelOne',
    title: 'Angel One',
    Rating: '4.5',
    img: 'brokerAngel',
    titleText: 'Discount Broker',
    aboutBroker:
      'Angel One is a full-service broker in India that offers a wide range of investment and trading services at costs comparable to those of discount brokers. As of October 2024, Angel One has an active client base of 75,29,017. ',
    productBasket:
      'The segments in which you can invest (includes Equity, Commodity, Currency, Futures and Options) through this broker',
    productBasketList: [
      {
        id: 1,
        name: 'Equity',
        background: '#abd1d257',
        dark: '#375c5c'
      },
      {
        id: 2,
        name: 'Commodity',
        background: '#e4deed',
        dark: '#8362b5'
      },
      {
        id: 3,
        name: 'Currency',
        background: '#edecde',
        dark: '#9d9524'
      },
      {
        id: 4,
        name: 'Futures',
        background: '#b0d0b7',
        dark: '#637868'
      },
      {
        id: 5,
        name: 'Options',
        background: '#b0b7d0',
        dark: '#5e6fa8'
      }
    ],
    brokaragePlan:
      '1 or more plans offered by this broker, which outlines, Account opening and maintenance fees charged by this broker, Brokerage charges and Margins provided. Use plan switcher to see the difference in offerings, if multiple plans are available. ',
    singlePlan: 'Single Plan',
    charges: {
      ['account opening']: '₹300',
      ['account maintenance']: '₹300',
      ['call trade']: '₹50'
    },
    Brokerage: {
      ['equity delivery']: {
        value1: 'Zero',
        value2: ''
      },
      ['equity intraday']: {
        value1: '0.03%',
        value2: 'up to Rs 20'
      },
      ['equity futures']: {
        value1: '0.03%',
        value2: 'up to Rs 20'
      },
      ['equity options']: {
        value1: '₹20',
        value2: 'per order'
      },
      ['currency futures']: {
        value1: '0.03%',
        value2: ''
      },
      ['currency options']: {
        value1: '₹20',
        value2: ''
      },
      ['commodity futures']: {
        value1: '0.03%',
        value2: ''
      },
      ['commodity options']: {
        value1: '₹20',
        value2: ''
      }
    },
    Margins: {
      ['Equity Delivery']: {
        value1: '0x',
        value2: 'As per exchange'
      },
      ['Equity Intraday']: {
        value1: '3x',
        value2: 'As per exchange'
      },
      ['Equity Futures']: {
        value1: '2.5x',
        value2: 'As per exchange'
      },
      ['Equity Options']: {
        value1: '2.5x',
        value2: 'As per exchange'
      },
      ['Currency Futures']: {
        value1: '2.5x',
        value2: 'As per exchange'
      },
      ['Currency Options']: {
        value1: '2.5x',
        value2: 'As per exchange'
      },
      ['Commodity Futures']: {
        value1: '2.5x',
        value2: 'As per exchange'
      },
      ['Commodity Options']: {
        value1: '0x',
        value2: 'As per exchange'
      }
    },
    PlatformsOffered:
      'Set of software & applications offered by the broker for executing buy and sell orders online. These are names of their portals and mobile apps, for full desc- Full Platform Details.',
    PlatformsOfferedArray: [
      'Angel Speed Pro',
      'Angel One Trade',
      'Angel One App',
      'Angel ARQ'
    ],
    OtherDetailsProsLine: 'Advantages and Strengths of this broker.',
    OtherDetailsProsList: [
      'Offers full-service brokerage services at low fees. ',
      'Charges a flat brokerage fee of only ₹20 across all segments. ',
      'Provides free tips on ARQ, the robo-advisory app. ',
      'Offers free advisory tips for mutual funds and equities. ',
      'Provides an option to invest in corporate bonds. '
    ],
    OtherDetailsconsLine:
      'Disadvantages of this broker that it needs to improve upon.',
    OtherDetailsconsList: [
      'It does not offer the facility of a 3-in-1 account.',
      'GTC and GTT facilities are not available.',
      'The cost of broker-assisted trades (call and trade) is ₹20 per executed transaction.',
      'Reviews indicate consistent cross-selling pressure from Angel One’s relationship managers to purchase additional products.'
    ],
    AdditionalFeatures:
      'Extra features provided by the broker at little or no extra cost. Like research reports, SMS alerts etc.',
    AdditionalFeaturesList: [
      {
        id: 1,
        name: '3 in 1 Account',
        icon: false
      },
      {
        id: 2,
        name: 'free trading calls',
        icon: false
      },
      {
        id: 3,
        name: 'free research',
        icon: false
      },
      {
        id: 4,
        name: 'sms alerts',
        icon: true
      },
      {
        id: 5,
        name: 'margin funding',
        icon: false
      },
      {
        id: 6,
        name: 'margin against share',
        icon: true
      }
    ],
    OtherInvestmentOptions:
      'Services provided by the broker for investing in other financial assets such as Mutual funds, Insurance, Bonds etc.',
    OtherInvestmentOptionsList: [
      {
        id: 1,
        name: 'mutual funds',
        icon: true
      },
      {
        id: 2,
        name: 'ipo platform',
        icon: true
      },
      {
        id: 3,
        name: 'bonds debt',
        icon: true
      },
      {
        id: 4,
        name: 'exchange traded funds',
        icon: true
      }
    ],
    equityChargesList: [
      {
        name: 'delivery',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'BSE:',
                value: '0.00375%'
              },
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.1%'
              }
            ]
          },
          {
            id: 4,
            title: 'sebi charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      },
      {
        name: 'intraday',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'BSE:',
                value: '0.00375%'
              },
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing Charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp Charge',
            values: [
              {
                heading: '',
                value: '₹ 0'
              }
            ]
          },
          {
            id: 4,
            title: 'Gst',
            values: [
              {
                heading: '',
                value: '18% on Brokerage + Transaction'
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.025%'
              }
            ]
          },
          {
            id: 4,
            title: 'sebi charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      },
      {
        name: 'future',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'NSE: ',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing Charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp Charge',
            values: [
              {
                heading: '',
                value: '₹ 0'
              }
            ]
          },
          {
            id: 4,
            title: 'Gst',
            values: [
              {
                heading: '',
                value: '18% on Brokerage + Transaction'
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.0125%'
              }
            ]
          },
          {
            id: 4,
            title: 'sebi charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      },
      {
        name: 'options',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing Charges',
            values: [
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp Charge',
            values: [
              {
                heading: '',
                value: '₹ 0'
              }
            ]
          },
          {
            id: 4,
            title: 'Gst',
            values: [
              {
                heading: '',
                value: '18% on Brokerage + Transaction'
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.125% buy 0.0625% sell'
              }
            ]
          },
          {
            id: 4,
            title: 'Sebi Charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      }
    ],
    openDeatAccountUrl: 'https://bit.ly/AngelOne-OpenAccount'
  },
  {
    id: 'upstox',
    title: 'Upstox',
    Rating: '4.5',
    img: 'brokerUpstox',
    titleText: 'Discount Broker',
    aboutBroker:
      'Backed by Tiger Global and Ratan Tata, Upstox is a discount broker that offers free third-party premium subscriptions upon account opening. As of February 2024, Upstox has an active client base of 2,501,173. ',
    productBasket:
      'The segments in which you can invest (includes Equity, Commodity, Currency, Futures and Options) through this broker',
    productBasketList: [
      {
        id: 1,
        name: 'Equity',
        background: '#abd1d257',
        dark: '#375c5c'
      },
      {
        id: 2,
        name: 'Commodity',
        background: '#e4deed',
        dark: '#8362b5'
      },
      {
        id: 3,
        name: 'Currency',
        background: '#edecde',
        dark: '#9d9524'
      },
      {
        id: 4,
        name: 'Futures',
        background: '#b0d0b7',
        dark: '#637868'
      },
      {
        id: 5,
        name: 'Options',
        background: '#b0b7d0',
        dark: '#5e6fa8'
      }
    ],
    brokaragePlan:
      '1 or more plans offered by this broker, which outlines, Account opening and maintenance fees charged by this broker, Brokerage charges and Margins provided. Use plan switcher to see the difference in offerings, if multiple plans are available. ',
    singlePlan: 'Single Plan',
    charges: {
      ['account opening']: 'Free',
      ['account maintenance']: 'Free',
      ['call trade']: '₹50'
    },
    Brokerage: {
      ['equity delivery']: {
        value1: '2.5%',
        value2: 'up to Rs 20'
      },
      ['equity intraday']: {
        value1: '0.05%',
        value2: 'up to Rs 20'
      },
      ['equity futures']: {
        value1: '0.03%',
        value2: 'up to Rs 20'
      },
      ['equity options']: {
        value1: '₹20',
        value2: 'per order'
      },
      ['currency futures']: {
        value1: '0.05%',
        value2: ''
      },
      ['currency options']: {
        value1: '₹20',
        value2: ''
      },
      ['commodity futures']: {
        value1: '0.05%',
        value2: ''
      },
      ['commodity options']: {
        value1: '₹20',
        value2: ''
      }
    },
    Margins: {
      ['Equity Delivery']: {
        value1: '4x',
        value2: 'As per exchange'
      },
      ['Equity Intraday']: {
        value1: '3x',
        value2: 'As per exchange'
      },
      ['Equity Futures']: {
        value1: '5x',
        value2: 'As per exchange'
      },
      ['Equity Options']: {
        value1: '1x',
        value2: 'As per exchange'
      },
      ['Currency Futures']: {
        value1: '1x',
        value2: 'As per exchange'
      },
      ['Currency Options']: {
        value1: '1x',
        value2: 'As per exchange'
      },
      ['Commodity Futures']: {
        value1: '1x',
        value2: 'As per exchange'
      },
      ['Commodity Options']: {
        value1: '1x',
        value2: 'As per exchange'
      }
    },
    PlatformsOffered:
      'Set of software & applications offered by the broker for executing buy and sell orders online. These are names of their portals and mobile apps, for full desc- Full Platform Details.',
    PlatformsOfferedArray: ['Pro Web Trading Platform', 'Upstox Pro Mobile'],
    OtherDetailsProsLine: 'Advantages and Strengths of this broker.',
    OtherDetailsProsList: [
      'The early morning newsletter from Upstox is meticulously crafted, offering excellent insights and a comprehensive market recap.',
      'Upstox provides delivery transactions at no cost, charges flat trading fees, and offers margining aagainst held shares.',
      'A wide array of knowledge resources is available, encompassing both technical analysis and basic investing principles.',
      'Users can easily toggle between the TradingView and ChartsIQ libraries.'
    ],
    OtherDetailsconsLine:
      'Disadvantages of this broker that it needs to improve upon.',
    OtherDetailsconsList: [
      'NRIs, HUFs, and companies can open a demat account only through an offline process.',
      'Customers must exercise caution when selecting mutual funds, as both direct and regul',
      'An additional fee of ₹20 per executed order is charged for intraday square-off orders if they are not squared off by the customer.'
    ],
    AdditionalFeatures:
      'Extra features provided by the broker at little or no extra cost. Like research reports, SMS alerts etc.',
    AdditionalFeaturesList: [
      {
        id: 1,
        name: '3 in 1 Account',
        icon: false
      },
      {
        id: 2,
        name: 'free trading calls',
        icon: false
      },
      {
        id: 3,
        name: 'free research',
        icon: false
      },
      {
        id: 4,
        name: 'sms alerts',
        icon: true
      },
      {
        id: 5,
        name: 'margin funding',
        icon: false
      },
      {
        id: 6,
        name: 'margin against share',
        icon: true
      }
    ],
    OtherInvestmentOptions:
      'Services provided by the broker for investing in other financial assets such as Mutual funds, Insurance, Bonds etc.',
    OtherInvestmentOptionsList: [
      {
        id: 1,
        name: 'mutual funds',
        icon: true
      },
      {
        id: 2,
        name: 'ipo platform',
        icon: true
      },
      {
        id: 3,
        name: 'bonds debt',
        icon: true
      },
      {
        id: 4,
        name: 'exchange traded funds',
        icon: true
      }
    ],
    equityChargesList: [
      {
        name: 'delivery',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'BSE:',
                value: '0.00375%'
              },
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.1%'
              }
            ]
          },
          {
            id: 4,
            title: 'sebi charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      },
      {
        name: 'intraday',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'BSE:',
                value: '0.00375%'
              },
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing Charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp Charge',
            values: [
              {
                heading: '',
                value: '₹ 0'
              }
            ]
          },
          {
            id: 4,
            title: 'Gst',
            values: [
              {
                heading: '',
                value: '18% on Brokerage + Transaction'
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.025%'
              }
            ]
          },
          {
            id: 4,
            title: 'sebi charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      },
      {
        name: 'future',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'NSE: ',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing Charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp Charge',
            values: [
              {
                heading: '',
                value: '₹ 0'
              }
            ]
          },
          {
            id: 4,
            title: 'Gst',
            values: [
              {
                heading: '',
                value: '18% on Brokerage + Transaction'
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.0125%'
              }
            ]
          },
          {
            id: 4,
            title: 'sebi charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      },
      {
        name: 'options',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing Charges',
            values: [
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp Charge',
            values: [
              {
                heading: '',
                value: '₹ 0'
              }
            ]
          },
          {
            id: 4,
            title: 'Gst',
            values: [
              {
                heading: '',
                value: '18% on Brokerage + Transaction'
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.125% buy 0.0625% sell'
              }
            ]
          },
          {
            id: 4,
            title: 'Sebi Charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      }
    ],
    openDeatAccountUrl: 'https://upstox.com/open-demat-account/'
  },
  {
    id: '5paisa',
    title: '5paisa',
    Rating: '4.5',
    img: 'brokerpaisa5',
    titleText: 'Discount Broker',
    aboutBroker:
      '5paisa is a discount broker that offers a range of tools and features tailored to both traders and investors. It is one of the fastest-growing discount brokers in India. As of February 2024, 5paisa has an active client base of 544,270. ',
    productBasket:
      'The segments in which you can invest (includes Equity, Commodity, Currency, Futures and Options) through this broker',
    productBasketList: [
      {
        id: 1,
        name: 'Equity',
        background: '#abd1d257',
        dark: '#375c5c'
      },
      {
        id: 2,
        name: 'Commodity',
        background: '#e4deed',
        dark: '#8362b5'
      },
      {
        id: 3,
        name: 'Currency',
        background: '#edecde',
        dark: '#9d9524'
      },
      {
        id: 4,
        name: 'Futures',
        background: '#b0d0b7',
        dark: '#637868'
      },
      {
        id: 5,
        name: 'Options',
        background: '#b0b7d0',
        dark: '#5e6fa8'
      }
    ],
    brokaragePlan:
      '1 or more plans offered by this broker, which outlines, Account opening and maintenance fees charged by this broker, Brokerage charges and Margins provided. Use plan switcher to see the difference in offerings, if multiple plans are available. ',
    singlePlan: 'Single Plan',
    charges: {
      ['account opening']: 'Free',
      ['account maintenance']: '₹300',
      ['call trade']: 'Free'
    },
    Brokerage: {
      ['equity delivery']: {
        value1: '₹20 ',
        value2: 'per order'
      },
      ['equity intraday']: {
        value1: '₹20',
        value2: 'per order'
      },
      ['equity futures']: {
        value1: '₹20',
        value2: 'per order'
      },
      ['equity options']: {
        value1: '₹20',
        value2: 'per order'
      },
      ['currency futures']: {
        value1: '₹20',
        value2: 'per order'
      },
      ['currency options']: {
        value1: '₹10',
        value2: 'per order'
      },
      ['commodity futures']: {
        value1: '₹20',
        value2: 'per order'
      },
      ['commodity options']: {
        value1: '₹20',
        value2: 'per order'
      }
    },
    Margins: {
      ['Equity Delivery']: {
        value1: '4x',
        value2: 'As per exchange'
      },
      ['Equity Intraday']: {
        value1: '3x',
        value2: 'As per exchange'
      },
      ['Equity Futures']: {
        value1: '5x',
        value2: 'As per exchange'
      },
      ['Equity Options']: {
        value1: '1x',
        value2: 'As per exchange'
      },
      ['Currency Futures']: {
        value1: '1x',
        value2: 'As per exchange'
      },
      ['Currency Options']: {
        value1: '1x',
        value2: 'As per exchange'
      },
      ['Commodity Futures']: {
        value1: '1x',
        value2: 'As per exchange'
      },
      ['Commodity Options']: {
        value1: '1x',
        value2: 'As per exchange'
      }
    },
    PlatformsOffered:
      'Set of software & applications offered by the broker for executing buy and sell orders online. These are names of their portals and mobile apps, for full desc- Full Platform Details.',
    PlatformsOfferedArray: ['Pro Web Trading Platform', 'Upstox Pro Mobile'],
    OtherDetailsProsLine: 'Advantages and Strengths of this broker.',
    OtherDetailsProsList: [
      "    5Paisa's brokerage plans cater to a wide range of individuals, including novices, investors, and professional traders.",
      'It provides an all-in-one account for investing in stocks, mutual funds, commodities, currency, along with research and advisory services.',
      'The platform offers free technical, derivative, and fundamental research alongside an advanced trading platform tailored for traders.',
      'It also includes complimentary stock research and advisory services.'
    ],
    OtherDetailsconsLine:
      'Disadvantages of this broker that it needs to improve upon.',
    OtherDetailsconsList: [
      'Exchange transaction charges are higher compared to other discount brokers.',
      'Call & Trade is available but at a significant additional cost.',
      '5Paisa does not provide NRI trading services.'
    ],
    AdditionalFeatures:
      'Extra features provided by the broker at little or no extra cost. Like research reports, SMS alerts etc.',
    AdditionalFeaturesList: [
      {
        id: 1,
        name: '3 in 1 Account',
        icon: false
      },
      {
        id: 2,
        name: 'free trading calls',
        icon: false
      },
      {
        id: 3,
        name: 'free research',
        icon: false
      },
      {
        id: 4,
        name: 'sms alerts',
        icon: true
      },
      {
        id: 5,
        name: 'margin funding',
        icon: false
      },
      {
        id: 6,
        name: 'margin against share',
        icon: true
      }
    ],
    OtherInvestmentOptions:
      'Services provided by the broker for investing in other financial assets such as Mutual funds, Insurance, Bonds etc.',
    OtherInvestmentOptionsList: [
      {
        id: 1,
        name: 'mutual funds',
        icon: true
      },
      {
        id: 2,
        name: 'ipo platform',
        icon: true
      },
      {
        id: 3,
        name: 'bonds debt',
        icon: true
      },
      {
        id: 4,
        name: 'exchange traded funds',
        icon: true
      }
    ],
    equityChargesList: [
      {
        name: 'delivery',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'BSE:',
                value: '0.00375%'
              },
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.1%'
              }
            ]
          },
          {
            id: 4,
            title: 'sebi charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      },
      {
        name: 'intraday',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'BSE:',
                value: '0.00375%'
              },
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing Charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp Charge',
            values: [
              {
                heading: '',
                value: '₹ 0'
              }
            ]
          },
          {
            id: 4,
            title: 'Gst',
            values: [
              {
                heading: '',
                value: '18% on Brokerage + Transaction'
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.025%'
              }
            ]
          },
          {
            id: 4,
            title: 'sebi charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      },
      {
        name: 'future',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'NSE: ',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing Charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp Charge',
            values: [
              {
                heading: '',
                value: '₹ 0'
              }
            ]
          },
          {
            id: 4,
            title: 'Gst',
            values: [
              {
                heading: '',
                value: '18% on Brokerage + Transaction'
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.0125%'
              }
            ]
          },
          {
            id: 4,
            title: 'sebi charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      },
      {
        name: 'options',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing Charges',
            values: [
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp Charge',
            values: [
              {
                heading: '',
                value: '₹ 0'
              }
            ]
          },
          {
            id: 4,
            title: 'Gst',
            values: [
              {
                heading: '',
                value: '18% on Brokerage + Transaction'
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.125% buy 0.0625% sell'
              }
            ]
          },
          {
            id: 4,
            title: 'Sebi Charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      }
    ],
    openDeatAccountUrl: 'https://bit.ly/AngelOne-OpenAccount'
  },
  {
    id: 'groww',
    title: 'groww',
    Rating: '4.5',
    img: 'brokergroww',
    titleText: 'Discount Broker',
    aboutBroker: `Armed with a vision to democratize investing and make it accessible and user-friendly, the four ambitious minds from Flipkart—Lalit Keshre, Harsh Jain, Ishan Bansal, and Neeraj Singh—founded Groww in 2017. In just seven years, Groww became India's number one stock broker, boasting the highest number of active investors. As of February 2024, Groww has an active client base of 9,185,024.`,
    productBasket:
      'The segments in which you can invest (includes Equity, Commodity, Currency, Futures and Options) through this broker',
    productBasketList: [
      {
        id: 1,
        name: 'Equity',
        background: '#abd1d257',
        dark: '#375c5c'
      },
      {
        id: 2,
        name: 'Commodity',
        background: '#e4deed',
        dark: '#8362b5'
      },
      {
        id: 3,
        name: 'Currency',
        background: '#edecde',
        dark: '#9d9524'
      },
      {
        id: 4,
        name: 'Futures',
        background: '#b0d0b7',
        dark: '#637868'
      },
      {
        id: 5,
        name: 'Options',
        background: '#b0b7d0',
        dark: '#5e6fa8'
      }
    ],
    brokaragePlan:
      '1 or more plans offered by this broker, which outlines, Account opening and maintenance fees charged by this broker, Brokerage charges and Margins provided. Use plan switcher to see the difference in offerings, if multiple plans are available. ',
    singlePlan: 'Single Plan',
    charges: {
      ['account opening']: 'Free',
      ['account maintenance']: 'Free',
      ['call trade']: '-'
    },
    Brokerage: {
      ['equity delivery']: {
        value1: '0.05%',
        value2: 'up to Rs 20'
      },
      ['equity intraday']: {
        value1: '0.05%',
        value2: 'up to Rs 20'
      },
      ['equity futures']: {
        value1: '₹20',
        value2: 'per order'
      },
      ['equity options']: {
        value1: '₹20',
        value2: 'per order'
      },
      ['currency futures']: {
        value1: '-',
        value2: ''
      },
      ['currency options']: {
        value1: '-',
        value2: ''
      },
      ['commodity futures']: {
        value1: '-',
        value2: ''
      },
      ['commodity options']: {
        value1: '-',
        value2: ''
      }
    },
    Margins: {
      ['Equity Delivery']: {
        value1: '0x',
        value2: 'As per exchange'
      },
      ['Equity Intraday']: {
        value1: '8x',
        value2: 'As per exchange'
      },
      ['Equity Futures']: {
        value1: '0x',
        value2: 'As per exchange'
      },
      ['Equity Options']: {
        value1: '0x',
        value2: 'As per exchange'
      },
      ['Currency Futures']: {
        value1: '-',
        value2: ''
      },
      ['Currency Options']: {
        value1: '-',
        value2: ''
      },
      ['Commodity Futures']: {
        value1: '-',
        value2: ''
      },
      ['Commodity Options']: {
        value1: '-',
        value2: ''
      }
    },
    PlatformsOffered:
      'Set of software & applications offered by the broker for executing buy and sell orders online. These are names of their portals and mobile apps, for full desc- Full Platform Details.',
    PlatformsOfferedArray: ['Website', 'Groww App'],
    OtherDetailsProsLine: 'Advantages and Strengths of this broker.',
    OtherDetailsProsList: [
      'Mutual fund investments are commission-free for customers.',
      'The process of opening a trading and Demat account is quick and straightforward.',
      'Users also have the option to purchase US equities, US ETFs, US fixed deposits, and digital gold.',
      'The platform offers a simple and intuitive user interface.'
    ],
    OtherDetailsconsLine:
      'Disadvantages of this broker that it needs to improve upon.',

    OtherDetailsconsList: [
      '      Groww does not provide commodity and currency trading options.',
      'The platform does not offer analysis, trading advice, or recommendations.',
      'Advanced order types such as GTT, Bracket, Cover orders, and AMO orders are not available.',
      'It does not offer research reports on stocks.'
    ],
    AdditionalFeatures:
      'Extra features provided by the broker at little or no extra cost. Like research reports, SMS alerts etc.',
    AdditionalFeaturesList: [
      {
        id: 1,
        name: '3 in 1 Account',
        icon: false
      },
      {
        id: 2,
        name: 'free trading calls',
        icon: false
      },
      {
        id: 3,
        name: 'free research',
        icon: false
      },
      {
        id: 4,
        name: 'sms alerts',
        icon: true
      },
      {
        id: 5,
        name: 'margin funding',
        icon: false
      },
      {
        id: 6,
        name: 'margin against share',
        icon: true
      }
    ],
    OtherInvestmentOptions:
      'Services provided by the broker for investing in other financial assets such as Mutual funds, Insurance, Bonds etc.',
    OtherInvestmentOptionsList: [
      {
        id: 1,
        name: 'mutual funds',
        icon: true
      },
      {
        id: 2,
        name: 'ipo platform',
        icon: true
      },
      {
        id: 3,
        name: 'bonds debt',
        icon: true
      },
      {
        id: 4,
        name: 'exchange traded funds',
        icon: true
      }
    ],
    equityChargesList: [
      {
        name: 'delivery',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'BSE:',
                value: '0.00375%'
              },
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.1%'
              }
            ]
          },
          {
            id: 4,
            title: 'sebi charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      },
      {
        name: 'intraday',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'BSE:',
                value: '0.00375%'
              },
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing Charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp Charge',
            values: [
              {
                heading: '',
                value: '₹ 0'
              }
            ]
          },
          {
            id: 4,
            title: 'Gst',
            values: [
              {
                heading: '',
                value: '18% on Brokerage + Transaction'
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.025%'
              }
            ]
          },
          {
            id: 4,
            title: 'sebi charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      },
      {
        name: 'future',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'NSE: ',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing Charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp Charge',
            values: [
              {
                heading: '',
                value: '₹ 0'
              }
            ]
          },
          {
            id: 4,
            title: 'Gst',
            values: [
              {
                heading: '',
                value: '18% on Brokerage + Transaction'
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.0125%'
              }
            ]
          },
          {
            id: 4,
            title: 'sebi charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      },
      {
        name: 'options',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing Charges',
            values: [
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp Charge',
            values: [
              {
                heading: '',
                value: '₹ 0'
              }
            ]
          },
          {
            id: 4,
            title: 'Gst',
            values: [
              {
                heading: '',
                value: '18% on Brokerage + Transaction'
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.125% buy 0.0625% sell'
              }
            ]
          },
          {
            id: 4,
            title: 'Sebi Charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      }
    ],
    openDeatAccountUrl: 'https://groww.in/'
  },
  {
    id: 'iciciDirect',
    title: 'ICICI Direct',
    Rating: '4.5',
    img: 'brokericici',
    titleText: 'Discount Broker',
    aboutBroker: `ICICI Securities Limited is a member of the nationally renowned ICICI Group. ICICI Direct, a prominent division of ICICI Securities, provides a wide array of investment options, including stocks, mutual funds, bonds, and more. As of March 2024, ICICI Direct has an active client base of 1,845,202.`,
    productBasket:
      'The segments in which you can invest (includes Equity, Commodity, Currency, Futures and Options) through this broker',
    productBasketList: [
      {
        id: 1,
        name: 'Equity',
        background: '#abd1d257',
        dark: '#375c5c'
      },
      {
        id: 2,
        name: 'Commodity',
        background: '#e4deed',
        dark: '#8362b5'
      },
      {
        id: 3,
        name: 'Currency',
        background: '#edecde',
        dark: '#9d9524'
      },
      {
        id: 4,
        name: 'Futures',
        background: '#b0d0b7',
        dark: '#637868'
      },
      {
        id: 5,
        name: 'Options',
        background: '#b0b7d0',
        dark: '#5e6fa8'
      }
    ],
    brokaragePlan:
      '1 or more plans offered by this broker, which outlines, Account opening and maintenance fees charged by this broker, Brokerage charges and Margins provided. Use plan switcher to see the difference in offerings, if multiple plans are available. ',
    singlePlan: 'Single Plan',
    charges: {
      ['account opening']: 'Free',
      ['account maintenance']: '₹700',
      ['call trade']: 'Free'
    },
    Brokerage: {
      ['equity delivery']: {
        value1: '0.55%',
        value2: 'on order value'
      },
      ['equity intraday']: {
        value1: '0.05%',
        value2: 'on order value'
      },
      ['equity futures']: {
        value1: '0.05%',
        value2: 'on order value'
      },
      ['equity options']: {
        value1: '₹95',
        value2: 'per order'
      },
      ['currency futures']: {
        value1: '₹20',
        value2: ''
      },
      ['currency options']: {
        value1: '₹20',
        value2: ''
      },
      ['commodity futures']: {
        value1: '₹20',
        value2: ''
      },
      ['commodity options']: {
        value1: '₹20',
        value2: ''
      }
    },
    Margins: {
      ['Equity Delivery']: {
        value1: '2x',
        value2: 'As per exchange'
      },
      ['Equity Intraday']: {
        value1: '7x',
        value2: 'As per exchange'
      },
      ['Equity Futures']: {
        value1: '4x',
        value2: 'As per exchange'
      },
      ['Equity Options']: {
        value1: '4x/2x',
        value2: 'As per exchange'
      },
      ['Currency Futures']: {
        value1: '4x',
        value2: 'As per exchange'
      },
      ['Currency Options']: {
        value1: '0x',
        value2: 'As per exchange'
      },
      ['Commodity Futures']: {
        value1: '2.5x',
        value2: 'As per exchange'
      },
      ['Commodity Options']: {
        value1: '0x',
        value2: 'As per exchange'
      }
    },
    PlatformsOffered:
      'Set of software & applications offered by the broker for executing buy and sell orders online. These are names of their portals and mobile apps, for full desc- Full Platform Details.',
    PlatformsOfferedArray: [
      'Market App',
      'Trade Racer',
      'i-Track',
      'Money App'
    ],

    OtherDetailsProsLine: 'Advantages and Strengths of this broker.',
    OtherDetailsProsList: [
      'Access a single website to invest online in mutual funds, IPOs, postal savings schemes, and GOI bonds.',
      'Experience a reduced bandwidth website for trading on smartphones or slower internet connections.',
      'Technical analysis and stock tips are offered to customers at no charge.',
      'Enjoy the convenience of placing multiple orders with Basket Order functionality.',
      'Utilize the featured platform, Sensibull, for options trading.'
    ],
    OtherDetailsconsLine:
      'Disadvantages of this broker that it needs to improve upon.',

    OtherDetailsconsList: [
      'ICICI Direct imposes high brokerage fees across various plans.',
      'A premium plan is necessary to access preferred brokerage rates.',
      'Higher brokerage fees are charged by ICICI Direct to customers who trade in penny stocks.',
      'The charge for call & trade is high (after exhausting the first 20 free calls in a month).',
      'There is no provision for free mutual fund investments.'
    ],
    AdditionalFeatures:
      'Extra features provided by the broker at little or no extra cost. Like research reports, SMS alerts etc.',
    AdditionalFeaturesList: [
      {
        id: 1,
        name: '3 in 1 Account',
        icon: false
      },
      {
        id: 2,
        name: 'free trading calls',
        icon: false
      },
      {
        id: 3,
        name: 'free research',
        icon: false
      },
      {
        id: 4,
        name: 'sms alerts',
        icon: true
      },
      {
        id: 5,
        name: 'margin funding',
        icon: false
      },
      {
        id: 6,
        name: 'margin against share',
        icon: true
      }
    ],
    OtherInvestmentOptions:
      'Services provided by the broker for investing in other financial assets such as Mutual funds, Insurance, Bonds etc.',
    OtherInvestmentOptionsList: [
      {
        id: 1,
        name: 'mutual funds',
        icon: true
      },
      {
        id: 2,
        name: 'ipo platform',
        icon: true
      },
      {
        id: 3,
        name: 'bonds debt',
        icon: true
      },
      {
        id: 4,
        name: 'exchange traded funds',
        icon: true
      }
    ],
    equityChargesList: [
      {
        name: 'delivery',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'BSE:',
                value: '0.00375%'
              },
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.1%'
              }
            ]
          },
          {
            id: 4,
            title: 'sebi charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      },
      {
        name: 'intraday',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'BSE:',
                value: '0.00375%'
              },
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing Charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp Charge',
            values: [
              {
                heading: '',
                value: '₹ 0'
              }
            ]
          },
          {
            id: 4,
            title: 'Gst',
            values: [
              {
                heading: '',
                value: '18% on Brokerage + Transaction'
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.025%'
              }
            ]
          },
          {
            id: 4,
            title: 'sebi charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      },
      {
        name: 'future',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'NSE: ',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing Charges',
            values: [
              {
                heading: '--',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp Charge',
            values: [
              {
                heading: '',
                value: '₹ 0'
              }
            ]
          },
          {
            id: 4,
            title: 'Gst',
            values: [
              {
                heading: '',
                value: '18% on Brokerage + Transaction'
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.0125%'
              }
            ]
          },
          {
            id: 4,
            title: 'sebi charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      },
      {
        name: 'options',
        value: [
          {
            id: 1,
            title: 'Transaction Charges',
            values: [
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 2,
            title: 'Clearing Charges',
            values: [
              {
                heading: 'NSE:',
                value: ''
              }
            ]
          },
          {
            id: 3,
            title: 'Dp Charge',
            values: [
              {
                heading: '',
                value: '₹ 0'
              }
            ]
          },
          {
            id: 4,
            title: 'Gst',
            values: [
              {
                heading: '',
                value: '18% on Brokerage + Transaction'
              }
            ]
          },
          {
            id: 4,
            title: 'stt',
            values: [
              {
                heading: '',
                value: '0.125% buy 0.0625% sell'
              }
            ]
          },
          {
            id: 4,
            title: 'Sebi Charges',
            values: [
              {
                heading: '',
                value: '₹ 10 per crore + GST'
              }
            ]
          }
        ]
      }
    ],
    openDeatAccountUrl: 'https://secure.icicidirect.com/accountopening'
  }
]

export const parseRegistrarDetail = detailString => {
  if (!detailString) return { name: '', phone: '', email: '', link: '' }
  const cleanedDetail = detailString.replace(/^"|"$/g, '')
  const [name, phone, email] = cleanedDetail.split(',').map(part => part.trim())
  return { name, phone, email }
}

export const extractWebsiteName = input => {
  const regex =
    /(http:\/\/|https:\/\/|www\.)[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[\w&%=?./-]*)?/

  const matchResult = input.match(regex)
  if (matchResult) {
    const url = matchResult[0]
    return url.replace(/^(http:\/\/|https:\/\/|www\.)/, '')
  }
  return null
}

export const isIOS = () => {
  return /iPhone|iPad|iPod/.test(navigator.userAgent) && !window.MSStream
}

export const isSafari = () => {
  return (
    navigator.userAgent.includes('Safari') &&
    !navigator.userAgent.includes('Chrome')
  )
}

export const isIOSAndSafari = () => {
  return isIOS() && isSafari()
}

export const isTodayOrBefore = date => {
  const today = new Date()
  const comparisonDate = new Date(date)
  comparisonDate.setDate(comparisonDate.getDate() + 1)
  return comparisonDate >= today
}

export const getToken = () => {
  const tokenData = localStorage.getItem('login')
  return tokenData ? JSON.parse(tokenData)?.access : null
}

// Utility to create headers
export const createHeaders = (
  isAuth = false,
  contentType = 'application/json'
) => {
  const headers = { 'Content-Type': contentType }
  if (isAuth) {
    const token = getToken()
    if (!token) throw new Error('User is not authenticated.')
    headers.Authorization = `Bearer ${token}`
  }
  return headers
}

// Common API handler
export const handleApiRequest = async (
  method,
  url,
  data = null,
  isAuth = false,
  contentType = 'application/json'
) => {
  try {
    const config = {
      method,
      url,
      headers: createHeaders(isAuth, contentType)
    }
    if (data) {
      if (contentType === 'multipart/form-data') {
        const formData = new FormData()
        Object.entries(data).forEach(([key, value]) => {
          if (value !== undefined) formData.append(key, value)
        })
        config.data = formData
      } else {
        config.data = data
      }
    }
    return await axiosInstance(config)
  } catch (error) {
    console.error(`API request failed: ${method.toUpperCase()} ${url}`, error)
    throw error
  }
}

export const decrypt = encryptedText => {
  const data = CryptoJS.enc.Base64.parse(encryptedText)
  const iv = CryptoJS.lib.WordArray.create(data.words.slice(0, 4)) // 16 bytes = 4 words
  const encryptedData = CryptoJS.lib.WordArray.create(data.words.slice(4))
  // const secretKey = 'c1f9f40ab75bc5f9f63e25a92cbd1cd7'
  const keyWordArray = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_DECRYPT)
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: encryptedData },
    keyWordArray,
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }
  )
  let de = decrypted.toString(CryptoJS.enc.Utf8)
  return de
}

export const checkUrl = ele => {
  let data = extractWebsiteName(ele.registrar_detail)
  if (data === null) {
    return false
  } else {
    return true
  }
}

export const generateToken = async token => {
  try {
    const response = await generateTokenApi(token)
    if (response.meta) {
      return false
    } else {
      const token = JSON.parse(localStorage.getItem('login')) || {}
      if (token) {
        token.access = response.access
        token.refresh = response.refresh
        localStorage.setItem('login', JSON.stringify(token))
      }
      return true
    }
  } catch (error) {
    return false
  }
}

export const navigateToLogin = (dispatch, navigate) => {
  dispatch(removeLoginData())
  dispatch(removeProfileData())
  navigate('/')
}

export const handleUnauthorized = async (callback, dispatch, navigate, ...args) => {
  try {
    const token = JSON.parse(localStorage.getItem('login'))?.refresh || ''
    const response = await generateToken(token)
    if (response) {
      return await callback(...args)
    } else {
      navigateToLogin(dispatch, navigate)
      return null
    }
  } catch (error) {
    console.error('Error handling unauthorized access:', error)
    navigateToLogin()
    return null
  }
}

export const formatDate = (isoString) => {
  const date = new Date(isoString);
  const options = { month: "short", day: "numeric", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options); 
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, 
  }); 

  return `${formattedDate} ${formattedTime}`;
};

export const PATHS = {

  // Public Routes
  LOGIN: '/login',
  SIGNUP: '/signup',

  // Protected Routes
  PROFILE: '/profile',
  APPLIED_IPO: '/appliedIpo',
  APPLY: '/apply/:id',
  MODIFY: '/modify/:id',


  // General Routes
  HOME: '/',
  IPODASHBOARD: '/ipoDashboard',
  IPODETAILS: '/ipoDetails',


  IPO_DETAIL: '/ipo/:id',
  CONTACT_US: '/contactUs',
  DELETEACCOUNT: '/deleteAccount',
  FORGOT_PASSWORD: '/forgotPassword',
  VERIFY_CODE: '/verifyCode',
  SET_NEW_PASSWORD: '/setNewPassword',
  BROKER: '/broker',
  NEWS: '/news',
  ABOUT_US: '/about-us',
  LIVE: '/live',
  UPCOMING: '/upcoming',
  LISTED: '/listed',
  TERMS_AND_CONDITIONS: '/termsAndConditions',
  PRIVACY_POLICY: '/privacyPolicy',
  PRIVACY2: '/appprivacypolicy',
  MARKET_NEWS_DETAIL: '/marketNews/:id',
  BROKER_DETAIL: '/broker/:id',
  FAQS: '/faqs',
  IPOSUBINNER:'/IpoSubscriptionInner/:id',
  APP_PRIVACY_POLICY: '/android_appPrivacyPolicy',
  // Catch-All Route
  NOT_FOUND: '/*', 
};